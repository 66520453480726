export const modalConfig = {
  allowOutsideClick: true,
  allowEnterKey: false,
  allowEscapeKey: true,
  backdrop: true,
  showClass: {
    popup: 'animate__animated animate__slideInDown animate__faster',
  },
  hideClass: {
    popup: 'animate__animated animate__fadeOutUp animate__faster',
  },
};
