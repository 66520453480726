import { CadastroProducaoFormValues, Producao } from "../../../assets/interfaces/Agricultor";

export const initialValues: CadastroProducaoFormValues = {
  id: '',
  quantidade_produzida: 0,
  area_produzida:0,
  custo_total: 0,
  //id_insumo: "",
  //quantidade_utilizada: 0,
  nCycle: 0,
  isPlanted: false
};
