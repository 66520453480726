import React, { useEffect, useState } from 'react';
import Navbar from '../Navbar';
import { ArrowLeft } from 'react-feather';
import styles from './Header.module.scss';
import { useHistory, Link, useLocation } from 'react-router-dom';

interface Props {
  titulo: string;
  descricao?: string;
}

export function Header({ titulo, descricao }: Props) {
  const history = useHistory();
  const location = useLocation();
  const [isPaginaInicial, setIsPaginaInicial] = useState(true);

  const handleClick = () => {
    history.goBack();
  }
  
  useEffect(() => {
    //console.log(isPaginaInicial)
    // Este código será executado sempre que o pathname mudar
    const path = location.pathname;
    let teste = ['/escola','/agricultor','/'].includes(path)?true:false
    setIsPaginaInicial(teste)
  }, [location.pathname]);

  return (
    <header className={styles.header}>
      <div className={styles.headerContainer}>
        <Navbar />

        <div className={styles.headerContent}>
          <h1>{titulo}</h1>

          {descricao && <p>{descricao}</p>}
        </div>
        <div className={styles.headerButton}>
          {!isPaginaInicial && <Link to="/">
            <button
            type='button'
            onClick={handleClick}
            >
              <ArrowLeft color='#00652e' size={'1.125rem'}/>
              Voltar
            </button>
          </Link>}
        </div>
      </div>
    </header>
  );
}
