import { XCircle } from "react-feather";
import "./styles.scss";

interface ErrorProps {
  text: string;
}

export function Error(props: ErrorProps) {
  return (
    <p id="error">
      <XCircle color="#ee5253" size="1.125rem" />
      {props.text}
    </p>
  );
}
