import * as Yup from 'yup';
import { CadastroProducaoFormValues } from '../../../assets/interfaces/Agricultor';

export const validationSchema: Yup.SchemaOf<CadastroProducaoFormValues> =
  Yup.object().shape({
    id: Yup.string().required('Este campo é de preenchimento obrigatório'),
    /*id_insumo: Yup.string().required(
      'Este campo é de preenchimento obrigatório'
    ),*/
    quantidade_produzida: Yup.number()
      .min(0, 'Insira um valor maior que 0')
      .required('Este campo é de preenchimento obrigatório'),
    area_produzida: Yup.number()
      .min(0, 'Insira um valor maior que 0')
      .required('Este campo é de preenchimento obrigatório'),
    /*quantidade_utilizada: Yup.number()
      .min(0, 'Insira um valor maior que 0')
      .required('Este campo é de preenchimento obrigatório'),*/
    custo_total: Yup.number()
      .min(0, 'Insira um valor maior que 0')
      .required('Este campo é de preenchimento obrigatório'),
    nCycle: Yup.number()
      .min(0, 'Insira um valor maior que 0')
      .required('Este campo é de preenchimento obrigatório'),
    isPlanted: Yup.bool()
      .required()
  });
