import React from "react";
import styles from "./FilterButton.module.scss";

interface FilterButtonProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export default function FilterButton({ onClick }: FilterButtonProps) {
  return (
    <button onClick={onClick} className={styles.filterButton}>
      Filtrar
    </button>
  );
}
