import { useContext, useEffect, useState } from "react";
import { opcoesProdutos } from "../../../../assets/utils/constants";
import {
  Calendar,
  ChevronDown,
  DollarSign,
  Edit3,
  Trash,
  Link as LinkIcon,
} from "react-feather";
import moment from "moment";
import Swal from "sweetalert2";
import { Collapse } from "react-collapse";
import { MultiSelect } from "primereact/multiselect";

import { Alimento, Edital } from "../../../../assets/interfaces/Edital";

import { Header } from "../../../../components/Header";
import { Loader } from "../../../../components/Loader";
import { Status } from "../../../../components/Status";
import FilterBar, {
  FiltrosEditaisAplicadosFormValues,
} from "../../../../components/FilterBar";

import { server } from "../../../../services/api";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import history from "../../../../routes/history";

import { EscolaContext } from "../../../../contexts/EscolaContext";

import emptyImage from "../../../../assets/images/empty.svg";
import "./styles.scss";
import FilterButton from "../../../../components/FIlterButton";
import { Input } from "../../../../components/Input";
import BaseButton from "../../../../components/BaseButton";
import { useHistory } from "react-router-dom";

interface IEditalSelecionado {
  editalId: String;
}
interface CheckboxState {
  [key: string]: boolean;
}
interface Checkbox {
  [key: string]: boolean;
}

export default function EditaisAbertos() {
  const history = useHistory();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filterValues, setFilterValues] = useState(
    {} as FiltrosEditaisAplicadosFormValues
  );

  const [editais, setEditais] = useState<Edital[]>([]);
  const [editaisSelecionados, setEditaisSelecionados] = useState<Edital[]>([]);
  const [selectedList, setSelectedList] = useState<IEditalSelecionado[]>([]);
  const [hasFiltred, setHasFiltred] = useState<Boolean>(false);
  const [openedIndex, setOpenedIndex] = useState<number | null>(null);
  const [hasRuned, sethasRuned] = useState(false);
  const { alterarStatusEdital, aplicarEdital, removerEdital } = useContext(EscolaContext);
  const [todosSelecionados, setTodosSelecionados] = useState(false);
  const [checkBoxes, setCheckboxes] = useState(false);

  const handleCheckboxChange = (nome: string) => {

  };

  const handleSelecionarTodos = (event: React.ChangeEvent<HTMLInputElement>) => {
    const todosSelecionados = event.target.checked;
    setCheckboxes(todosSelecionados);
  };

  const getEditais = async () => {
    const { data } = await trackPromise(server.get("/editais", {params:{a:1}}), "editais");

    if (data.results) {
      let editais = data.results.map((edital: Edital) => {
        return {
          ...edital,
        };
      });

      setEditais(editais);
    }
  };

  useEffect(() => {
    getEditais();
  }, []);

  const editaisPromise = usePromiseTracker({ area: "editais" });

  const [selectedFilterProducts, setSelectedFilterProducts] = useState(null);

  const chamarDSS1 = async () => {
    var editaisTemp: IEditalSelecionado[] = [];
    editaisSelecionados.forEach((edital) => {
      console.log(edital);

      editaisTemp = editaisTemp.concat({editalId: edital.id} as IEditalSelecionado);
    });
    //return;
    const { data } = await trackPromise(
      server.post("/dss1", { selecionados: editaisTemp }),
      "editais"
    );
    let edital_recomendado = 8
    if (data.results) {
      let editais = data.results.map((edital: Edital) => {
        edital.alimentos = edital.alimentos.map(alimento => {
          if(alimento.recomendado) edital_recomendado = 7
          return {
            ...alimento,
            selecionado: alimento.recomendado ? true : false,
            status: alimento.recomendado ? 7 : 8,
          }
        })
        return {
          ...edital,
          status: edital_recomendado,
        };
      });
      setEditais(editais);
      setEditaisSelecionados(editais);
    }  
    sethasRuned(true);
  };

  const selecionaAlimento = (idEdital: string, idAlimento: string) => {
    if (!idEdital || !idAlimento) return ;

    let _editais = editais.map(edital => {
      if(edital.id == idEdital) {
        edital.alimentos = edital.alimentos.map(alimento => {
          if(alimento.id == idAlimento) {
            alimento.selecionado = !alimento.selecionado
          }
          return alimento
        })
      }

      return edital
    })

    if(_editais) setEditais(_editais)
    
  }

  const handleAplicar = async () => {
    // console.log("1", editaisSelecionados);
    await Promise.all(editaisSelecionados.map(async (edital: any) => {
      let alimentos_selecionados = edital.alimentos.filter((alimento: any) => alimento.selecionado).map((alimento: any) => alimento.id)
      if(!alimentos_selecionados.length) return
      // pegar alimentos selecionados

      await aplicarEdital({
        editalId: edital.id,
        alimentosSelecionados: alimentos_selecionados
      });
      
    }));
   
    sethasRuned(false);
    history.push("/agricultor/editais/aplicados");
    //getEditais();
  };

  const handleParticipar = (editalId: String) => {
    if (editalId === "") {
      throw new Error(
        "Edital com ID nulo, verifique os editais que estão chegando da api"
      );
    }

    if (selectedList.find((edital) => edital.editalId === editalId)) {
      removeEdital(editalId);
    } else {
      addEdital(editalId);
    }
  };

  const removeEdital = (editalId: String) => {
    let editalToChange = editais.find((edital) => edital.id === editalId);
    editalToChange!.status = 1;

    let newEditais = editais.filter((edital) => edital.id !== editalId);
    newEditais.push(editalToChange!);

    setEditais([...newEditais]);

    let newSelected = selectedList.filter(
      (edital) => edital.editalId !== editalId
    );

    setSelectedList([...newSelected]);
  };

  const addEdital = (editalId: String) => {
    let editalToChange = editais.find((edital) => edital.id === editalId);
    editalToChange!.status = 5;

    let newEditais = editais.filter((edital) => edital.id !== editalId);

    setEditais([editalToChange!, ...newEditais]);

    const editalToAdd: IEditalSelecionado = {
      editalId,
    };

    setSelectedList([editalToAdd, ...selectedList]);
  };

  function handleChangeCheckbox(edital: Edital) {
    if (editaisSelecionados.includes(edital)) {
      const newEditais = editaisSelecionados.filter((item) => item !== edital);
      setEditaisSelecionados(newEditais);
    }
    else {
      const newEditais = editaisSelecionados.concat(edital);
      setEditaisSelecionados(newEditais);
    }
  }

  function handleChangeSelect(alimento:string) {
    
    
  }

  return (
    <>
      <Header
        titulo="Editais abertos"
        descricao="Visão geral de todos os editais disponíveis para candidatura."
      />

      <main id="editais-abertos">
        <div className="editais-abertos-card">
          {hasFiltred && <Loader area="editais" />}

          {!hasFiltred && (
            <div className="pre-filter">
              <MultiSelect
                value={selectedFilterProducts}
                options={opcoesProdutos}
                onChange={(e) => setSelectedFilterProducts(e.value)}
                placeholder="Selecione os produtos para filtrar"
                filter
                className="multiselect-custom"
                display="chip"
              />

                  <FilterButton
                onClick={() => {
                  setHasFiltred(true);
                }}
              />
            </div>
          )}

          {hasFiltred && editais.length > 0 && editais && (
            <div className="table editais-abertos">
              <FilterBar
                setFilterFields={setFilterValues}
                setSelectedFilterProducts={setSelectedFilterProducts}
                productsFilterOptions={opcoesProdutos}
                selectedFilterProducts={selectedFilterProducts}
              ></FilterBar>

              <div className="table-head">
                <div className="table-row">
                  <div className="table-headers">Processo</div>

                  <div className="table-headers sm-display">
                    Chamada Pública
                  </div>

                  <div className="table-headers sm-display">Nome do Edital</div>

                  <div className="table-headers sm-display">Data Inicial</div>

                  <div className="table-headers sm-display">Data Final</div>

                  <div className="table-headers">Status</div>

                  <div className="table-headers" aria-hidden="true"></div>
                </div>
              </div>

              <div className="confirm-container">
                {!hasRuned ? (
                  <>
                  <div className="table-body">
                {editais &&
                  editais.map((edital, index) => {
                    return (
                      <div key={edital.id} className="row-container">
                        <span className="checkbox-container">
                          <input className="checkbox-input" type="checkbox" onChange={() => handleChangeCheckbox(edital)} />
                        </span>
                        <div className="table-row">
                          <div className="table-column">
                            {edital.numero_processo}
                          </div>

                          <div className="table-column sm-display">
                            {edital.chamada_publica}
                          </div>

                          <div className="table-column sm-display">
                            {edital.nome}
                          </div>

                          <div className="table-column sm-display">
                            {moment(edital.data_inicio).format("DD/MM/YYYY")}
                          </div>

                          <div className="table-column sm-display">
                            {moment(edital.data_final).format("DD/MM/YYYY")}
                          </div>

                          <div className="table-column">
                            <Status status={edital.status || 0} />
                          </div>
                          <div className="table-column">
                            <button
                              type="button"
                              onClick={() => {
                                if (openedIndex !== index) {
                                  setOpenedIndex(index);
                                } else {
                                  setOpenedIndex(null);
                                }
                              }}
                            >
                              <ChevronDown color="#333333" size="1rem" />
                            </button>
                          </div>
                        </div>

                        <Collapse isOpened={openedIndex === index}>
                          <div className="content">
                            <section className="edital-card">
                              <div className="edital-card-header">
                                <div>
                                  <h2>
                                    {edital.nome}{" "}
                                    <Status status={edital.status || 0} />
                                  </h2>
                                </div>

                                <h3>
                                  Chamada Pública Nº {edital.chamada_publica}
                                </h3>

                                <h4>Processo nº {edital.numero_processo}</h4>
                              </div>

                              <div className="edital-card-body">
                                <div>
                                  <h6>ENTIDADE</h6>

                                  <p>{edital.entidade.nome_fantasia ?? ""}</p>
                                </div>

                                <div>
                                  <h6>CNPJ DA ENTIDADE</h6>

                                  <p>{edital.entidade.cnpj ?? ""}</p>
                                </div>

                                <div>
                                  <h6>ENDEREÇO</h6>

                                  <p>
                                    {edital.entidade.endereco?.logradouro ?? ""}
                                  </p>
                                </div>

                                <div>
                                  <h6>MUNICÍPIO/UF</h6>

                                  <p>
                                    {edital.entidade.endereco?.cidade ?? ""}
                                  </p>
                                </div>

                                <div>
                                  <h6>REPRESENTANTE DA ENTIDADE</h6>

                                  {edital.entidade.nome_representante}
                                </div>

                                <div>
                                  <h6>CPF DO REPRESENTANTE</h6>

                                  <p>{edital.entidade.cpf_representante}</p>
                                </div>

                                {/* <div>
                                  <h6>TELEFONE</h6>

                                  <p>{edital.entidade.telefone}</p>
                                </div> */}

                                <div>
                                  <h6>Data inicial</h6>

                                  <p>
                                    <Calendar color="#333333" size="1rem" />
                                    {moment(edital.data_inicio).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </p>
                                </div>

                                <div>
                                  <h6>Data de inicio da inscrição</h6>

                                  <p>
                                    <Calendar color="#333333" size="1rem" />
                                    {moment(edital.data_inicio_inscricao).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </p>
                                </div>

                                <div>
                                  <h6>Data final para inscrição</h6>

                                  <p>
                                    <Calendar color="#333333" size="1rem" />
                                    {moment(edital.data_fim_inscricao).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </p>
                                </div>

                                <div>
                                  <h6>Data de Seleção dos projetos de venda</h6>

                                  <p>
                                    <Calendar color="#333333" size="1rem" />
                                    {moment(edital.data_selecao).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </p>
                                </div>

                                <div>
                                  <h6>Data final</h6>

                                  <p>
                                    <Calendar color="#333333" size="1rem" />
                                    {moment(edital.data_final).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </p>
                                </div>

                                 {!!edital.url_arquivo && (
                                  <div>
                                    <h6>URL do edital</h6>

                                    <p>
                                      <LinkIcon color="#333333" size="1rem" />{" "}
                                      <a href={edital.url_arquivo.startsWith('http') ? edital.url_arquivo : "https://"+edital.url_arquivo} target="_blank">
                                        {edital.url_arquivo}
                                      </a>
                                    </p>
                                  </div>
                                  )} 

                                <div>
                                  <h6>
                                    Total de todos os alimentos a serem
                                    adquiridos
                                  </h6>

                                  {/* <p>
                                    <DollarSign color="#333333" size="1rem" />
                                    {new Intl.NumberFormat("pt-BR", {
                                      style: "currency",
                                      currency: "BRL",
                                    }).format(
                                      Number(
                                        edital.alimentos.reduce(function (
                                          prev,
                                          current
                                        ) {
                                          return (
                                            prev +
                                            current.valor * current.quantidade
                                          );
                                        },
                                        0)
                                      )
                                    )}
                                  </p> */}
                                </div>

                                <div>
                                  <h6>Quantitativo de gêneros alimentícios</h6>

                                  {edital.alimentos?.length > 0 ? (
                                    <table>
                                      <thead>
                                        <tr>
                                          {/* <th scope="col">Select</th> */}
                                          <th scope="col">Produto</th>
                                          <th scope="col">Unidade</th>
                                          {/* <th scope="col">
                                            Quantidade Desejada
                                          </th> */}
                                          <th scope="col">Quantidade Maxima</th>
                                          <th scope="col">Valor unitário</th>
                                          <th scope="col">Valor total</th>
                                        </tr>
                                      </thead>

                                      <tbody>
                                        {edital.alimentos.map((alimento) => {
                                          return (
                                            <tr key={alimento.nome}>
                                              {/* levar pra aplicados
                                              <td
                                                width="10%"
                                                data-label="Selecionar"
                                                className="select-row"
                                              >
                                                <div className="select-wrapper">
                                                  <input
                                                    type="checkbox"
                                                    onChange={(event) =>
                                                      handleChangeSelect(
                                                        edital,
                                                        alimento
                                                      )
                                                    }
                                                  ></input>
                                                </div>
                                              </td> */}
                                              <td data-label="Produto">
                                                {alimento.nome}
                                              </td>
                                              <td data-label="Unidade">
                                                {alimento.unidade}
                                              </td>
                                              {/*
                                              levar pra aplicados 
                                              <td data-label="Quantidade Desejada">
                                                <div>
                                                  <Input
                                                    name="Quantidade Desejada"
                                                    value={alimento.quantidade}
                                                    onChange={() => {}}
                                                  >
                                                    <></>
                                                  </Input>
                                                </div>
                                              </td> */}
                                              <td data-label="Quantidade Maxima">
                                                {alimento.quantidade}
                                              </td>
                                              <td data-label="Valor unitário">
                                                {new Intl.NumberFormat(
                                                  "pt-BR",
                                                  {
                                                    style: "currency",
                                                    currency: "BRL",
                                                  }
                                                ).format(
                                                  Number(alimento.valor)
                                                )}
                                              </td>
                                              <td data-label="Valor total">
                                                {new Intl.NumberFormat(
                                                  "pt-BR",
                                                  {
                                                    style: "currency",
                                                    currency: "BRL",
                                                  }
                                                ).format(
                                                  Number(
                                                    alimento.valor *
                                                      alimento.quantidade
                                                  )
                                                )}
                                              </td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </table>
                                  ) : (
                                    <p>
                                      Não há gêneros alimentícios cadastrados
                                      neste edital.
                                    </p>
                                  )}
                                </div>
                              </div>
                            </section>
                          </div>
                        </Collapse>
                      </div>
                    );
                  })}
              </div>
                  <BaseButton onClick={() => chamarDSS1()}>
                    Enviar Editais Para Classificação
                  </BaseButton>
                  </>
                ) : (
                  <>
                  <div className="table-body">
                {editais &&
                  editais.map((edital, index) => {
                    return (
                      <div key={edital.id} className="row-container">
                        <span className="checkbox-container">
                          <input className="checkbox-input" type="checkbox" onChange={handleSelecionarTodos} />
                        </span>
                        <div className="table-second">
                          <div>
                            <div className="table-row">
                              <div className="table-column">
                                {edital.numero_processo}
                              </div>

                              <div className="table-column sm-display">
                                {edital.chamada_publica}
                              </div>

                              <div className="table-column sm-display">
                                {edital.nome}
                              </div>

                              <div className="table-column sm-display">
                                {moment(edital.data_inicio).format("DD/MM/YYYY")}
                              </div>

                              <div className="table-column sm-display">
                                {moment(edital.data_final).format("DD/MM/YYYY")}
                              </div>

                              
                            </div>
                            <div>
                                <h6>Selecione os alimentos que deseja participar</h6>

                                {edital.alimentos?.length > 0 ? (
                                  <table className="table">
                                    <thead>
                                      <tr>
                                        <th scope="col">Select</th>
                                        <th scope="col">Produto</th>
                                        <th scope="col">Unidade</th>
                                        {/* <th scope="col">
                                          Quantidade Desejada
                                        </th> */}
                                        <th scope="col">Quantidade Maxima</th>
                                        <th scope="col">Valor unitário</th>
                                        <th scope="col">Valor total</th>
                                        <th scope="col">Status</th>
                                      </tr>
                                    </thead>

                                    <tbody>
                                      {edital.alimentos.map((alimento) => {
                                        return (
                                          <tr key={alimento.nome}>
                                            <td
                                              width="10%"
                                              data-label="Selecionar"
                                              className="select-row"
                                            >
                                              <div className="select-wrapper" >
                                                <input
                                                  type="checkbox" checked={alimento.selecionado}
                                                  onChange={() => selecionaAlimento(edital.id || "", alimento.id || "")}
                                                  
                                                ></input>
                                              </div>
                                            </td> 
                                            <td data-label="Produto">
                                              {alimento.nome}
                                            </td>
                                            <td data-label="Unidade">
                                              {alimento.unidade}
                                            </td>
                                            {/*
                                            levar pra aplicados 
                                            <td data-label="Quantidade Desejada">
                                              <div>
                                                <Input
                                                  name="Quantidade Desejada"
                                                  value={alimento.quantidade}
                                                  onChange={() => {}}
                                                >
                                                  <></>
                                                </Input>
                                              </div>
                                            </td> */}
                                            <td data-label="Quantidade Maxima">
                                              {alimento.quantidade}
                                            </td>
                                            <td data-label="Valor unitário">
                                              {new Intl.NumberFormat(
                                                "pt-BR",
                                                {
                                                  style: "currency",
                                                  currency: "BRL",
                                                }
                                              ).format(
                                                Number(alimento.valor)
                                              )}
                                            </td>
                                            <td data-label="Valor total">
                                              {new Intl.NumberFormat(
                                                "pt-BR",
                                                {
                                                  style: "currency",
                                                  currency: "BRL",
                                                }
                                              ).format(
                                                Number(
                                                  alimento.valor *
                                                    alimento.quantidade
                                                )
                                              )}
                                            </td>
                                            <td>
                                            <div className="table-column">
                                              <Status status={alimento.status || 0} />
                                              </div>
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                ) : (
                                  <p>
                                    Não há gêneros alimentícios cadastrados
                                    neste edital.
                                  </p>
                                )}
                            </div>
                          </div>
                        <div className="table-column">
                          <button
                            type="button"
                            onClick={() => {
                              if (openedIndex !== index) {
                                setOpenedIndex(index);
                              } else {
                                setOpenedIndex(null);
                              }
                            }}
                          >
                            <ChevronDown color="#333333" size="1rem" />
                          </button>
                        </div>
                        </div>
                        <Collapse isOpened={openedIndex === index}>
                          <div className="content">
                            <section className="edital-card">
                              <div className="edital-card-header">
                                <div>
                                  <h2>
                                    {edital.nome}{" "}
                                    <Status status={edital.status || 0} />
                                  </h2>
                                </div>

                                <h3>
                                  Chamada Pública Nº {edital.chamada_publica}
                                </h3>

                                <h4>Processo nº {edital.numero_processo}</h4>
                              </div>

                              <div className="edital-card-body">
                                <div>
                                  <h6>ENTIDADE</h6>

                                  <p>{edital.entidade.nome_fantasia ?? ""}</p>
                                </div>

                                <div>
                                  <h6>CNPJ DA ENTIDADE</h6>

                                  <p>{edital.entidade.cnpj ?? ""}</p>
                                </div>

                                <div>
                                  <h6>ENDEREÇO</h6>

                                  <p>
                                    {edital.entidade.endereco?.logradouro ?? ""}
                                  </p>
                                </div>

                                <div>
                                  <h6>MUNICÍPIO/UF</h6>

                                  <p>
                                    {edital.entidade.endereco?.cidade ?? ""}
                                  </p>
                                </div>

                                <div>
                                  <h6>REPRESENTANTE DA ENTIDADE</h6>

                                  {edital.entidade.nome_representante}
                                </div>

                                <div>
                                  <h6>CPF DO REPRESENTANTE</h6>

                                  <p>{edital.entidade.cpf_representante}</p>
                                </div>

                                {/* <div>
                                  <h6>TELEFONE</h6>

                                  <p>{edital.entidade.telefone}</p>
                                </div> */}

                                <div>
                                  <h6>Data inicial</h6>

                                  <p>
                                    <Calendar color="#333333" size="1rem" />
                                    {moment(edital.data_inicio).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </p>
                                </div>

                                <div>
                                  <h6>Data final</h6>

                                  <p>
                                    <Calendar color="#333333" size="1rem" />
                                    {moment(edital.data_final).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </p>
                                </div>

                                 {!!edital.url_arquivo && (
                                  <div>
                                    <h6>URL do edital</h6>

                                    <p>
                                      <LinkIcon color="#333333" size="1rem" />{" "}
                                      <a href={"https://"+edital.url_arquivo} target="_blank">
                                        {edital.url_arquivo}
                                      </a>
                                    </p>
                                  </div>
                                  )} 

                                <div>
                                  <h6>
                                    Total de todos os alimentos a serem
                                    adquiridos
                                  </h6>

                                  {/* <p>
                                    <DollarSign color="#333333" size="1rem" />
                                    {new Intl.NumberFormat("pt-BR", {
                                      style: "currency",
                                      currency: "BRL",
                                    }).format(
                                      Number(
                                        edital.alimentos.reduce(function (
                                          prev,
                                          current
                                        ) {
                                          return (
                                            prev +
                                            current.valor * current.quantidade
                                          );
                                        },
                                        0)
                                      )
                                    )}
                                  </p> */}
                                </div>
                              </div>
                            </section>
                          </div>
                        </Collapse>
                      </div>
                    );
                  })}
              </div>
                  <div className="submit-buttons-row">
                    <BaseButton onClick={() => handleAplicar()}>
                      Aplicar para editais
                    </BaseButton>
                    <BaseButton
                      onClick={() => {
                        getEditais();
                        sethasRuned(false);
                      }}
                    >
                      Simular novamente
                    </BaseButton>
                  </div>
                  </>
                )}
              </div>
            </div>
          )}

          {!editais.length && !editaisPromise.promiseInProgress && (
            <div className="nenhum-edital">
              <img src={emptyImage} alt="Nenhum edital encontrado" />

              {!hasRuned ? (
                <h1>Não encontramos nenhum edital cadastrado.</h1>
              ) : (
                <h1>
                  Oops, não recomendamos nenhum edital selecionado, tente
                  simular novamente...
                </h1>
              )}

              {hasRuned && (
                <BaseButton
                  onClick={() => {
                    getEditais();
                    sethasRuned(false);
                  }}
                >
                  Recarregar
                </BaseButton>
              )}
            </div>
          )}
        </div>
      </main>
      <br />
    </>
  );
}
