import { Form, Formik, FormikHelpers } from "formik";
import { useContext } from "react";
import Swal from "sweetalert2";
import * as Yup from "yup";

import { AuthContext } from "../../../contexts/AuthContext";

import { modalSenha } from "../../../components/ModalSenha";
import { modalConfig } from "../../../assets/utils/modalConfig";
import history from "../../../routes/history";

import logo from "../../../assets/images/logo.svg";

import styles from "../../../common/Acesso.module.scss";
import InputWithMask from "../../../components/InputWithMask";

import { truncateString } from "../../../assets/utils/functions";

interface AgricultorAcessoFormValues {
  cpfOuCnpj: string;
  senha: string;
}

export default function AgricultorAcesso() {
  const { entrar } = useContext(AuthContext);

  const valoresIniciais: AgricultorAcessoFormValues = {
    cpfOuCnpj: "",
    senha: "",
  };

  const validationSchema: Yup.SchemaOf<AgricultorAcessoFormValues> =
    Yup.object().shape({
      cpfOuCnpj: Yup.string()
        .matches(
          /(^\d{3}.\d{3}.\d{3}-\d{2}|^\d{2}.\d{3}.\d{3}\/\d{4}-\d{2})/,
          "CPF ou CNPJ inválido."
        )
        .required("Este campo é obrigatório."),
      senha: Yup.string().required("Este campo é obrigatório."),
    });


  const disparaAlertaErro: (msg: String) => Promise<any> = (msg) => {
    return Swal.fire({
      icon: "error",
      title: "Oops...",
      text: `${msg}`
    });
  }

  async function handleSubmitForm(
    values: AgricultorAcessoFormValues,
    actions: FormikHelpers<AgricultorAcessoFormValues>
  ) {
    actions.validateForm();
    // actions.resetForm();
    try {
      await entrar(values.cpfOuCnpj, values.senha, 1);
    } catch (err: any) {
      console.error(err.message)
      if(!err.message) {
        err.message = "Erro desconhecido, tente novamente mais tarde !!"
      }
      
      err.message = truncateString(err.message, 100)
      await disparaAlertaErro(err.message)
      actions.setStatus(<span> {err.message} </span>);

      throw err
    }
  }

  return (
    <main className={styles.login}>
      <section className={styles.loginCard}>
        <img src={logo} alt="Assistente PNAE" />

        <div>
          <h2>Assistente PNAE</h2>

          <p>
            Entre em sua conta como <strong>agricultor</strong>
          </p>
        </div>

        <Formik
          initialValues={valoresIniciais}
          validationSchema={validationSchema}
          onSubmit={handleSubmitForm}
        >
          {({
            touched,
            values,
            errors,
            handleChange,
            status,
            setFieldValue,
          }) => (
            <Form>
              <div className={styles.formGroup}>
                <div className={styles.inputGroup}>
                  <label htmlFor="cpfOuCnpj">
                    CPF ou CNPJ{" "}
                    {errors.cpfOuCnpj && touched.cpfOuCnpj ? (
                      <span>{errors.cpfOuCnpj}</span>
                    ) : null}
                  </label>

                  <InputWithMask
                    value={values.cpfOuCnpj}
                    onChange={(event) =>
                      setFieldValue("cpfOuCnpj", event.target.value)
                    }
                    name="cpfOuCnpj"
                    placeholder="Insira seu cpf ou cnpj"
                  ></InputWithMask>
                </div>

                <div className={styles.inputGroup}>
                  <label htmlFor="senha">
                    Senha{" "}
                    {errors.senha && touched.senha ? (
                      <span>{errors.senha}</span>
                    ) : null}
                  </label>

                  <input
                    type="password"
                    name="senha"
                    placeholder="Senha"
                    onChange={handleChange}
                    value={values.senha}
                  />
                </div>
              </div>

              <p style={{color: "#FF6347", fontWeight: "bold"}}>{status}</p>

              <button
                type="button"
                className={styles.botaoSenha}
                onClick={() => modalSenha()}
              >
                Esqueceu a senha?
              </button>

              <button type="submit">Entrar</button>
            </Form>
          )}
        </Formik>

        <button
          type="button"
          className={styles.botaoOutline}
          onClick={() =>
            Swal.fire({
              title: "Agricultor ou escola?",
              text: "Escolha a opção em que deseja se cadastrar",
              confirmButtonText: "Agricultor",
              denyButtonText: "Escola",
              showCancelButton: false,
              showCloseButton: false,
              showDenyButton: true,
              ...modalConfig,
            }).then((result) => {
              if (result.isConfirmed) {
                history.push("/agricultor/cadastrar");
              } else if (result.isDenied) {
                history.push("/escola/cadastrar");
              }
            })
          }
        >
          Cadastrar
        </button>
      </section>
    </main>
  );
}
