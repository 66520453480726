import { Form, Formik, FormikHelpers } from "formik";
import { useContext } from "react";
import Swal from "sweetalert2";
import * as Yup from "yup";

import { AuthContext } from "../../../contexts/AuthContext";

import { modalSenha } from "../../../components/ModalSenha";
import { modalConfig } from "../../../assets/utils/modalConfig";
import history from "../../../routes/history";

import logo from "../../../assets/images/logo.svg";

import styles from "../../../common/Acesso.module.scss";
import InputWithMask from "../../../components/InputWithMask";

interface EscolaAcessoFormValues {
  cnpj: string;
  senha: string;
}

export default function EscolaAcesso() {
  const { entrar } = useContext(AuthContext);

  const valoresIniciais: EscolaAcessoFormValues = {
    cnpj: "",
    senha: "",
  };

  const validationSchema: Yup.SchemaOf<EscolaAcessoFormValues> =
    Yup.object().shape({
      cnpj: Yup.string()
        .matches(/^\d{2}.\d{3}.\d{3}\/\d{4}-\d{2}$/, "CNPJ inválido.")
        .required("Este campo é obrigatório."),
      senha: Yup.string().required("Este campo é obrigatório."),
    });

  async function handleSubmitForm(
    values: EscolaAcessoFormValues,
    actions: FormikHelpers<EscolaAcessoFormValues>
  ) {
    try {
      actions.validateForm();
      // actions.resetForm();
      await entrar(values.cnpj, values.senha, 2);
    } catch(e) {
      if (e instanceof Error) {
        alert(e.message)
      } else {
        console.error("Ocorreu um erro não identificado.");
      }
    }

  }

  return (
    <main className={styles.login}>
      <section className={styles.loginCard}>
        <img src={logo} alt="Assistente PNAE" />

        <div>
          <h2>Assistente PNAE</h2>

          <p>
            Entre em sua conta como <strong>escola</strong>
          </p>
        </div>

        <Formik
          initialValues={valoresIniciais}
          validationSchema={validationSchema}
          onSubmit={handleSubmitForm}
        >
          {({ touched, values, errors, handleChange, setFieldValue }) => (
            <Form>
              <div className={styles.formGroup}>
                <div className={styles.inputGroup}>
                  <label htmlFor="cnpj">
                    CNPJ{" "}
                    {errors.cnpj && touched.cnpj ? (
                      <span>{errors.cnpj}</span>
                    ) : null}
                  </label>

                  <InputWithMask
                    value={values.cnpj}
                    onlyCNPJ={true}
                    onChange={(event) =>
                      setFieldValue("cnpj", event.target.value)
                    }
                    name="cnpj"
                    placeholder="Insira seu cnpj"
                  ></InputWithMask>
                </div>

                <div className={styles.inputGroup}>
                  <label htmlFor="senha">
                    Senha{" "}
                    {errors.senha && touched.senha ? (
                      <span>{errors.senha}</span>
                    ) : null}
                  </label>

                  <input
                    type="password"
                    name="senha"
                    placeholder="Senha"
                    onChange={handleChange}
                    value={values.senha}
                  />
                </div>
              </div>

              <button
                type="button"
                className={styles.botaoSenha}
                onClick={() => modalSenha()}
              >
                Esqueceu a senha?
              </button>

              <button type="submit">Entrar</button>
            </Form>
          )}
        </Formik>

        <button
          type="button"
          className={styles.botaoOutline}
          onClick={() =>
            Swal.fire({
              title: "Agricultor ou escola?",
              text: "Escolha a opção em que deseja se cadastrar",
              confirmButtonText: "Agricultor",
              denyButtonText: "Escola",
              showCancelButton: false,
              showCloseButton: false,
              showDenyButton: true,
              ...modalConfig,
            }).then((result) => {
              if (result.isConfirmed) {
                history.push("/agricultor/cadastrar");
              } else if (result.isDenied) {
                history.push("/escola/cadastrar");
              }
            })
          }
        >
          Cadastrar
        </button>
      </section>
    </main>
  );
}
