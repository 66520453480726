import {
  EditalAndProdutoFormsValues,
  EditalCadastroFormValues,
} from "../../../assets/interfaces/Edital";

export const initialValues: EditalAndProdutoFormsValues = {
  id: "",
  alimento_requisitado_id: "",
  nome: "",
  data_inicio: "",
  data_final: "",
  data_fim_inscricao: "",
  data_inicio_inscricao: "",
  data_selecao: "",
  chamada_publica: "",
  numero_processo: "",
  url_arquivo: "",
  produto: { label: "", id: "" },
  quantidade: 0,
  unidade: "",
  valor_unitario: 0,
  valor_total: 0,
};

// // Use for manual Tests
// export const initialValues: EditalAndProdutoFormsValues = {
//   nome: "Edital Teste",
//   data_inicio: "01/08/2023",
//   data_final: "01/09/2023",
//   chamada_publica: "1122233444",
//   numero_processo: "1122233444",
//   url_arquivo: "teste.com",
//   quantidade: 10,
//   unidade: "kg",
//   valor_unitario: 20,
//   valor_total: 200,
//   produto: { label: "", id: "" },
// };
