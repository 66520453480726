import * as Yup from "yup";

import { EscolaCadastroFormValues } from "../../../assets/interfaces/Escola";

export const validationSchema: Yup.SchemaOf<EscolaCadastroFormValues> =
  Yup.object().shape({
    tipo: Yup.number()
      .required("Este campo é de preenchimento obrigatório.")
      .integer("Campo inválido.")
      .positive("Campo inválido.")
      .min(1, "Campo inválido.")
      .max(2, "Campo inválido."),
    nome_fantasia: Yup.string().required(
      "O campo nome fantasia é de preenchimento obrigatório."
    ),
    razao_social: Yup.string().required(
      "O campo razão social é de preenchimento obrigatório."
    ),
    nome_representante: Yup.string().required(
      "O campo nome do representante é de preenchimento obrigatório"
    ),
    cpf_representante: Yup.string()
      .matches(/^\d{3}.\d{3}.\d{3}-\d{2}$/, "CPF inválido.")
      .required("O campo CPF do representante é de preenchimento obrigatório."),
    email_representante: Yup.string()
      .email("Email inválido.")
      .required("O campo nome é de preenchimento obrigatório."),
    cnpj: Yup.string()
      .matches(/^\d{2}.\d{3}.\d{3}\/\d{4}-\d{2}$/, "CNPJ inválido.")
      .required("O campo CNPJ é de preenchimento obrigatório."),
    cep: Yup.string()
      .min(9, "CEP inválido.")
      .max(9, "CEP inválido.")
      .matches(/^\d{5}-\d{3}$/, "CEP inválido.")
      .required("O campo CEP é de preenchimento obrigatório."),
    telefone: Yup.string()
      .matches(/(\(\d{2}\)\s)?(\d{4,5}-\d{4})/, "Telefone inválido.")
      .required("O campo telefone é de preenchimento obrigatório."),
    logradouro: Yup.string(),
    complemento: Yup.string(),
    bairro: Yup.string(),
    cidade: Yup.string().required(
      "O campo cidade é de preenchimento obrigatório."
    ),
    estado: Yup.string()
      .max(2, "Estado inválido.")
      .required("O campo estado é de preenchimento obrigatório."),
    senha: Yup.string()
      .min(6, "A senha precisa conter no mínimo 6 caracteres")
      .required("O campo senha é de preenchimento obrigatório."),
    senha_confirmacao: Yup.string()
      .oneOf([Yup.ref("senha"), null], "As senhas não coincidem.")
      .required("O campo confirme a senha é de preenchimento obrigatório."),
  });
