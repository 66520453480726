import { Header } from '../../../components/Header';

import history from '../../../routes/history';

import { ArrowRight, Edit, FileText, Inbox, MousePointer } from 'react-feather';

import styles from '../../../common/Opcoes.module.scss';

export default function EscolaOpcoes() {
  return (
    <>
      <Header
        titulo='Navegue por categoria'
        descricao='Encontre o serviço ou a informação desejada pelas opções a seguir.'
      />

      <main className={styles.opcoes}>
        <div className={styles.card}>
          <div>
            <h3>Cadastrar edital</h3>

            <button
              type='button'
              onClick={() => {
                history.push('/escola/edital');
              }}
            >
              Cadastre um edital
              <ArrowRight color='#333333' size='1rem' />
            </button>
          </div>

          <Inbox color='#00933B' size='5rem' strokeWidth='1' />
        </div>

        <div className={styles.card}>
          <div>
            <h3>Editais</h3>

            <button
              type='button'
              onClick={() => {
                history.push('/escola/editais');
              }}
            >
              Veja os editais cadastrados
              <ArrowRight color='#333333' size='1rem' />
            </button>
          </div>

          <FileText color='#00933B' size='5rem' strokeWidth='1' />
        </div>

        <div className={styles.card}>
          <div>
            <h3>Selecionar Propostas</h3>

            <button
              type='button'
              onClick={() => {
                history.push('/escola/propostas');
              }}
            >
              Veja as propostas cadastradas
              <ArrowRight color='#333333' size='1rem' />
            </button>
          </div>

          <MousePointer color='#00933B' size='4.5rem' strokeWidth='1' />
        </div>

        <div className={styles.card}>
          <div>
            <h3>Propostas Contratadas</h3>

            <button
              type='button'
              onClick={() => {
                history.push('/escola/contrato');
              }}
            >
              Veja as propostas que foram contratadas
              <ArrowRight color='#333333' size='1rem' />
            </button>
          </div>

          <Edit color='#00933B' size='5rem' strokeWidth='1' />
        </div>
      </main>
      <br />
    </>
  );
}
