import { FocusEvent, useContext, useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import bancos from 'bancos-brasileiros';


import { Error } from '../Error';
import { Input } from '../Input';

import styles from '../../common/Cadastro.module.scss';

interface SenhaFormInterface {
  senha: string;
  senha_confirmacao: string;
}

export default function SenhaForm({isEdditing} : {isEdditing: boolean}) {
  const { values, errors, touched, handleChange } = useFormikContext<SenhaFormInterface>();

  return (
    <>                   
      <h3>Senha</h3>

      <div className={styles.formGroup}>
        <Input
          isRequired
          label='Senha'
          name='senha'
          onChange={handleChange}
          type='password'
          placeholder={values.senha}
        >
          {errors.senha && touched.senha ? (
            <Error text={errors.senha} />
          ) : null}
        </Input>

        <Input
          isRequired
          label='Confirme a senha'
          name='senha_confirmacao'
          onChange={handleChange}
          type='password'
          placeholder={values.senha_confirmacao}
        >
          {errors.senha_confirmacao && touched.senha_confirmacao ? (
            <Error text={errors.senha_confirmacao} />
          ) : null}
        </Input>
      </div>
    </>
  );
}
