import Routes from "./routes";

import { ToastContainer } from "react-toastify";

import { AuthProvider } from "./contexts/AuthContext";
import { AgricultorProvider } from "./contexts/AgricultorContext";
import { EscolaProvider } from "./contexts/EscolaContext";

import "animate.css";
import "./assets/styles/global.scss";
import "../node_modules/react-tippy/dist/tippy.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "../node_modules/react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <AuthProvider>
      <EscolaProvider>
        <AgricultorProvider>
          <ToastContainer
            position="top-left"
            autoClose={30000}
            hideProgressBar
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable={false}
            pauseOnHover
          />
          <Routes />
        </AgricultorProvider>
      </EscolaProvider>
    </AuthProvider>
  );
}

export default App;
