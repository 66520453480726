import { ArrowRight } from 'react-feather';
import { Link } from 'react-router-dom';

import history from '../../routes/history';

import ufg from '../../assets/images/logos/Universidade_Federal_de_Goias.png';
import strath from '../../assets/images/logos/University_of_Strathclyde.png';
import usp from '../../assets/images/logos/Universidade_de_Sao_Paulo.png';
import kit from '../../assets/images/logos/Karlsruhe_Institute_of_Technology.png';
import upc from '../../assets/images/logos/Universidad_Peruana_de_Ciencias_Aplicadas.png';

import fapeg from '../../assets/images/logos/FAPEG.png';
import newtonFund from '../../assets/images/logos/Newton_Fund.png';
import britishCouncil from '../../assets/images/logos/British_Council.png';
import cnpq from '../../assets/images/logos/CNPQ.png';
import mda from '../../assets/images/logos/MDA.png'

import styles from './Card.module.scss';

interface Props {
  tipo: 'agricultor' | 'escola' | 'desenvolvedores' | 'financiadores';
}

export function Card({ tipo }: Props) {
  if (tipo === 'agricultor') {
    return (
      <div className={`${styles.card} ${styles.agricultorCard}`}>
        <h3>
          Agricultores e<br />
          fornecedores
        </h3>

        <button
          type='button'
          onClick={() => {
            history.push('/agricultor/acessar');
          }}
        >
          Entre em sua conta
          <ArrowRight color='#333333' size='1rem' />
        </button>
      </div>
    );
  } else if (tipo === 'escola') {
    return (
      <div className={`${styles.card} ${styles.escolaCard}`}>
        <h3>
          Escolas e<br />
          secretarias
        </h3>

        <button
          type='button'
          onClick={() => {
            history.push('/escola/acessar');
          }}
        >
          Entre em sua conta
          <ArrowRight color='#333333' size='1rem' />
        </button>
      </div>
    );
  } else if (tipo === 'desenvolvedores') {
    return (
      <div className={`${styles.card} ${styles.desenvolvedoresCard}`}>
        <h3>Desenvolvedores</h3>

        <div className={styles.logosImages}>
          <Link to='/ufg'>
            <img src={ufg} alt='Universidade Federal de Goiás' />
          </Link>

          <Link to='/strath'>
            <img src={strath} alt='University of Strathclyde' />
          </Link>

          <Link to='/usp'>
            <img src={usp} alt='Universidade de São Paulo' />
          </Link>

          <Link to='/kit'>
            <img src={kit} alt='Karlsruhe Institute of Technology' />
          </Link>

          <Link to='/upc'>
            <img src={upc} alt='Universidad Peruana de Ciencias Aplicadas' />
          </Link>
        </div>
      </div>
    );
  } else {
    return (
      <div className={`${styles.card} ${styles.financiadoresCard}`}>
        <h3>Financiadores</h3>

        <div className={styles.logosImages}>
          <Link to='/fapeg'>
            <img
              src={fapeg}
              alt='Fundação de Amparo à Pesquisa do Estado de Goiás'
            />
          </Link>

          <Link to='/newtonFund'>
            <img src={newtonFund} alt='Newton Fund' />
          </Link>

          <Link to='/britishCouncil'>
            <img src={britishCouncil} alt='British Council' />
          </Link>

          <Link to='/cnpq'>
            <img
              src={cnpq}
              alt='Conselho Nacional de Desenvolvimento Científico e Tecnológico'
            />
          </Link>
          <div className={styles.mda}>
          <Link to='/mda'>
            <img
              src={mda}
              alt='Ministério de Desenvolvimento Agrário e Agricultura Familiar'
            />
          </Link>
          </div>
        </div>
      </div>
    );
  }
}
