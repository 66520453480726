/* eslint-disable no-useless-escape */
import * as Yup from "yup";

import { EditalCadastroFormValues } from "../../../assets/interfaces/Edital";

export const validationSchema: Yup.SchemaOf<EditalCadastroFormValues> =
  Yup.object().shape({
    nome: Yup.string().required("Este campo é de preenchimento obrigatório."),
    data_inicio: Yup.string().default(''),
    data_final: Yup.string().default(''),
    data_inicio_inscricao: Yup.string()
      .required("O campo data de inicio da inscrição é de preenchimento obrigatório.")
      .matches(/^\d{2}\/\d{2}\/\d{4}$/, "Data inicio da inscrição inválida."),
    data_fim_inscricao: Yup.string()
      .required("O campo data final para inscrição é de preenchimento obrigatório.")
      .matches(/^\d{2}\/\d{2}\/\d{4}$/, "Data final para inscrição inválida."),
    data_selecao: Yup.string()
      .required("O campo data de seleção dos projetos de venda é de preenchimento obrigatório.")
      .matches(/^\d{2}\/\d{2}\/\d{4}$/, "Data de seleção inválida."),
    chamada_publica: Yup.string().required(
      "O campo número da chamada pública é de preenchimento obrigatório."
    ),
    numero_processo: Yup.string().required(
      "O campo número do processo é de preenchimento obrigatório."
    ),
    url_arquivo: Yup.string().matches(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
      "URL inválida"
    ),
  });
