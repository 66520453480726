import { usePromiseTracker } from "react-promise-tracker";

import ReactLoading from "react-loading";

import "./styles.scss";

interface LoaderProps {
  area: string;
}

export function Loader({ area }: LoaderProps) {
  const { promiseInProgress } = usePromiseTracker({ area: area });

  return (
    <>
      {promiseInProgress && (
        <div className="loader">
          <ReactLoading
            type={"spin"}
            color={"#00933b"}
            height={"3rem"}
            width={"3rem"}
          />
        </div>
      )}
    </>
  );
}
