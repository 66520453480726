import { AgricultorCadastroFormValues } from "../../../assets/interfaces/Agricultor";

// Use for manual tests:
export const initialValues: AgricultorCadastroFormValues = {
  tipo: 1,
  nome_proponente: "",
  email: "",
  cpf: "",
  cnpj: "",
  telefone: "",
  celular: "",
  dap: "",
  cep: "",
  logradouro: "",
  complemento: "",
  bairro: "",
  cidade: "",
  estado: "",
  banco: "",
  area_produzida: 0,
  conta_bancaria: "",
  agencia: "",
  operacao: "",
  senha: "",
  senha_confirmacao: "",
  numero_associados: 0,
  numero_associados_lei: 0,
  numero_associados_dap: 0,
  representante_legal: "",
  representante_cpf: "",
  ic_priorizado: false,
  associados:[]
};
