import * as Yup from "yup";
import { CadastroInsumoFormValues } from "../../../assets/interfaces/Agricultor";

export const validationSchema: Yup.SchemaOf<CadastroInsumoFormValues> =
  Yup.object().shape({
    descricao: Yup.string().required(
      "Este campo é de preenchimento obrigatório"
    ),
    id_categoria: Yup.string()
      .required("Este campo é de preenchimento obrigatório")
      .notOneOf(["0"], "Este campo é de preenchimento obrigatório."),
    unidade: Yup.string().required("Este campo é de preenchimento obrigatório"),
    custo_unitario: Yup.number()
    .min(0, "O custo não pode ser menor que 0.")
    .required("Este campo é de preenchimento obrigatório"),
  });
