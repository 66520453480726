import { useContext, useEffect, useState } from "react";
import Collapsible from "react-collapsible";
import { ChevronDown, Edit3, Trash } from "react-feather";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";

import { ProducaoFormatada } from "../../../assets/interfaces/Agricultor";

import { Link } from "react-router-dom";

import { Header } from "../../../components/Header";
import { Loader } from "../../../components/Loader";

import { server } from "../../../services/api";

import history from "../../../routes/history";

import { AgricultorContext } from "../../../contexts/AgricultorContext";

import emptyImage from "../../../assets/images/empty.svg";
import "./styles.scss";
import Swal from "sweetalert2";

export default function Producoes() {
  const { buscarProducaoPorId, removerAlimentoProduzidos } = useContext(AgricultorContext);

  const [producoes, setProducoes] = useState<ProducaoFormatada[]>([]);
  const [qtdInsumosCadastrados, setQtdInsumosCadastrados] = useState(0);

  useEffect(() => {
    const getProducoes = async () => {
      const { data } = await trackPromise(
        server.get("/producoes"),
        "producoes"
      );

      const insumos = await server.get("/insumos");
      console.log(insumos); 
      setQtdInsumosCadastrados(insumos.data.resultado.qtdeInsumosCadastrados);
      console.log(data);

      if (data.results) {
        const todasProducoes: ProducaoFormatada[] = [];
        data.results.forEach(async (resultado: any) => {
          const novaProducao: ProducaoFormatada = {
            id_relation: resultado.id,
            id: resultado.alimento.nome,
            custo_total: resultado.custo,
            quantidade_produzida: resultado.cababilidade,
           // listaInsumos: resultado.insumos || [],
            nCycle: resultado.nCycle,
            isPlanted: resultado.isPlanted
          };

          todasProducoes.push(novaProducao);
          setProducoes([...todasProducoes]);
        });

        
      }
    };
    getProducoes();
  }, [buscarProducaoPorId]);

  const producoesPromise = usePromiseTracker({ area: "producoes" });

  const collapsibleEditalProps = {
    classParentString: "producao",
    contentContainerTagName: "div",
    triggerTagName: "div",
    className: "table-body",
    openedClassName: "table-body",
    triggerClassName: "table-row",
    triggerOpenedClassName: "table-row",
  };

  const categoria = (id: string) => {
    switch (id) {
      case "1":
        return "Maquinário";
      case "2":
        return "Fertilizantes";
      case "3":
        return "Inseticidas";
      case "4":
        return "Processamentos";
      case "5":
        return "Embalagem";
      case "6":
        return "Mão de obra";
      case "7":
        return "Herbicidas";
      case "8":
        return "Sementes/Mudas/Ramas";
    }
  };

  return (
    <>
      <Header
        titulo="Produções"
        descricao="Cadastre os alimentos que são produzidos."
      />

      <main className="accordion">
        <div className="accordionCard">
          <div className="accordionTitle">
            <h2>Produções</h2>

            <button
              onClick={() => { history.push("/agricultor/producao")
                {/*qtdInsumosCadastrados > 0
                  ? history.push("/agricultor/producao")
                  : Swal.fire({
                      title: "Nenhum insumo",
                      text: "É necessário cadastrar novos insumos para acessar esta função",
                      denyButtonText: "Cadastrar insumo",
                      showConfirmButton: false,
                      showCancelButton: false,
                      showDenyButton: true,
                      showCloseButton: false,
                      allowOutsideClick: true,
                      allowEnterKey: false,
                      allowEscapeKey: true,
                      backdrop: true,
                      customClass: {
                        denyButton: "swal-deny-button",
                      },
                      showClass: {
                        popup:
                          "animate__animated animate__slideInDown animate__faster",
                      },
                      hideClass: {
                        popup:
                          "animate__animated animate__fadeOutUp animate__faster",
                      },
                    }).then((result: any) => {
                      if (result.isDenied) {
                        history.push("/agricultor/insumo");
                      }
                    });*/}
              }}
            >
              Nova produção
            </button>
          </div>

          <Loader area="producoes" />

          {!!producoes.length && (
            <div className="table producoes">
              <div className="table-head">
                <div className="table-row">
                  <div className="table-headers">Produção</div>

                  <div className="table-headers sm-display">
                    Quantidade produzida
                  </div>

                  <div className="table-headers sm-display">
                    Valor por unidade
                  </div>

                  <div className="table-headers sm-display">Valor total</div>

                  <div className="table-headers" aria-hidden="true"></div>
                </div>
              </div>

              {!!producoes.length &&
                producoes.map((producao) => {
                  return (
                    <Collapsible
                      key={producao.id}
                      {...collapsibleEditalProps}
                      trigger={
                        <>
                          <div className="table-column">{producao.id}</div>

                          <div className="table-column sm-display">
                            {producao.quantidade_produzida}
                          </div>

                          <div className="table-column sm-display">
                            {new Intl.NumberFormat("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            }).format(
                              Number(
                                producao.custo_total /
                                  producao.quantidade_produzida
                              )
                            )}
                          </div>

                          <div className="table-column sm-display">
                            {new Intl.NumberFormat("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            }).format(Number(producao.custo_total))}
                          </div>

                          <div className="table-column">
                            <button type="button">
                              <ChevronDown color="#333333" size="1rem" />
                            </button>
                          </div>
                        </>
                      }
                    >
                      <section className="producao-card">
                        <div className="producao-card-header">
                          <div>
                            <h2>{producao.id}</h2>

                            <div>
                              <button
                                type="button"
                                className="icon editar-producao"
                                onClick={() =>
                                  history.push(
                                    `/agricultor/producao/${producao.id_relation}`
                                  )
                                }
                              >
                                <Edit3 color="#777777" size="1rem" />
                              </button>

                              <button
                                type='button'
                                className='icon remover-producao'
                                onClick={() => {
                                  Swal.fire({
                                    title: 'Excluir produção?',
                                    text: 'Essa ação não pode ser desfeita.',
                                    confirmButtonText: 'Excluir produção',
                                    cancelButtonText: 'Cancelar',
                                    showConfirmButton: true,
                                    showCancelButton: true,
                                    showDenyButton: false,
                                    showCloseButton: false,
                                    allowOutsideClick: true,
                                    allowEnterKey: false,
                                    allowEscapeKey: true,
                                    backdrop: true,
                                    customClass: {
                                      confirmButton: 'swal-confirm-button',
                                      cancelButton: 'swal-cancel-button',
                                    },
                                    showClass: {
                                      popup:
                                        'animate__animated animate__slideInDown animate__faster',
                                    },
                                    hideClass: {
                                      popup:
                                        'animate__animated animate__fadeOutUp animate__faster',
                                    },
                                  }).then((result: any) => {
                                    if (result.isConfirmed) {
                                      removerAlimentoProduzidos(producao.id_relation || "");

                                      const filteredProducao = producoes.filter(
                                        (elementoProducao) => elementoProducao.id_relation !== producao.id_relation
                                      );

                                      setProducoes([...filteredProducao]);
                                    }
                                  });
                                }}
                              >
                                <Trash color='#ff6b6b' size='1rem' />
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="producao-card-body">
                          <div>
                            <h6>Quantidade produzida</h6>

                            <p>{producao.quantidade_produzida}</p>
                          </div>

                          <div>
                            <h6>Valor por unidade</h6>

                            <p>
                              {new Intl.NumberFormat("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                              }).format(
                                Number(
                                  producao.custo_total /
                                    producao.quantidade_produzida
                                )
                              )}
                            </p>
                          </div>

                          <div>
                            <h6>Valor total</h6>

                            <p>
                              {new Intl.NumberFormat("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                              }).format(Number(producao.custo_total))}
                            </p>
                          </div>

                         {/* <div>
                            <h6>Insumos</h6>

                            {producao.listaInsumos?.length > 0 ? (
                              <table>
                                <thead>
                                  <tr>
                                    <th scope="col">Categoria</th>
                                    <th scope="col">Descrição</th>
                                    <th scope="col">Unidade</th>
                                    <th scope="col">Quantidade utilizada</th>
                                    <th scope="col">Custo unitário</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {producao.listaInsumos?.map((insumo) => {
                                    return (
                                      <tr key={insumo.insumo.descricao}>
                                        <td data-label="Categoria">
                                          {categoria(
                                            insumo.insumo.id_categoria
                                          )}
                                        </td>
                                        <td data-label="Descrição">
                                          {insumo.insumo.descricao}
                                        </td>
                                        <td data-label="Unidade">
                                          {insumo.insumo.unidade}
                                        </td>
                                        <td data-label="Quantidade utilizada">
                                          {
                                            insumo.insumoProducao
                                              .quantidade_utilizada
                                          }
                                        </td>
                                        <td data-label="Custo unitário">
                                          {new Intl.NumberFormat("pt-BR", {
                                            style: "currency",
                                            currency: "BRL",
                                          }).format(
                                            Number(insumo.insumoProducao.custo)
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            ) : (
                              <p>Não há insumos cadastrados nesta produção.</p>
                            )}
                          </div>*/}
                        </div>
                      </section>
                    </Collapsible>
                  );
                })}
            </div>
          )}

          {!producoes.length && !producoesPromise.promiseInProgress && (
            <div className="empty">
              <img src={emptyImage} alt="Nenhuma produção encontrada" />

              <h1>Não encontramos nenhuma produção cadastrada.</h1>

              {qtdInsumosCadastrados !== 0 ? (
                <p>
                  Que tal{" "}
                  <Link to="/agricultor/producao">
                    cadastrar uma nova produção
                  </Link>{" "}
                  agora?
                </p>
              ) : (
                <p>
                  {/*É necessário{" "}
                  <Link to="/agricultor/insumo">cadastrar insumos</Link> antes
                  de cadastrar uma produção.*/}
                  Faça o cadastro da produção.
              </p>
              )}
            </div>
          )}
        </div>
      </main>
      <br />
    </>
  );
}
