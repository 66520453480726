import { createContext, ReactNode, useEffect, useState } from "react";

import { server } from "../services/api";

import history from "../routes/history";

import jwt_decode from "jwt-decode";

import Cookies from "js-cookie";
import { AxiosError } from "axios";

type User = {
  usuario: string;
  id: string;
  tipo: number;
};

interface AuthContextData {
  entrar: (login: string, senha: string, tipo: number) => Promise<void>;
  sair: () => void;
  estaAutenticado: boolean;
  usuario: User | undefined;
}

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthContext = createContext({} as AuthContextData);

export function AuthProvider({ children }: AuthProviderProps) {
  const [usuario, setUsuario] = useState<User | undefined>();
  const estaAutenticado = !!usuario;

  useEffect(() => {
    const { "auth.token": token } = Cookies.get();

    if (token) {
      const decodedToken: any = jwt_decode(token);

      setUsuario({
        usuario: decodedToken.usuario,
        tipo: decodedToken.tipo,
        id: decodedToken.id,
      });
    } else {
      // sair();
    }
  }, []);

  function sair() {
    Cookies.remove("auth.token");
    //Cookies.remove("auth.refreshToken");

    setUsuario(undefined);

    history.push("/");
  }

  async function entrar(login: string, senha: string, tipo: number) {
    let serverUrl = "";
    let redirectUrl = "";

    if (tipo === 1) {
      serverUrl = "/agricultores/login";
      redirectUrl = "/agricultor";
    } else {
      serverUrl = "/entidades/login";
      redirectUrl = "/escola";
    }

    try {
      const response = await server.post(serverUrl, {
        login,
        senha,
      });

      const { token } = response.data;
      const decodedToken: any = jwt_decode(token);

      Cookies.set("auth.token", token, {
        expires: 60 * 60 * 24, // 1 dia
        path: "/",
      });

      setUsuario({
        usuario: decodedToken.usuario,
        id: decodedToken.id,
        tipo: decodedToken.tipo,
      });

      server.defaults.headers["Authorization"] = `Bearer ${token}`;

      history.push(redirectUrl);
    } catch (error) {
      const err = error as AxiosError;
      if (err.response && err.response.status === 400) {
        throw Error(err.response.data.error);
      }

      if(err.message == "Network Error") {
        throw Error("Verifique a conexão com a internet e tente novamente !!");
      }

      throw err
    }
  }
  return (
    <AuthContext.Provider value={{ entrar, sair, estaAutenticado, usuario }}>
      {children}
    </AuthContext.Provider>
  );
}
