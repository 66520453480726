import { FormikErrors, FormikTouched } from "formik";
import React from "react";
import {
  EditalCadastroFormValues,
  ProdutoFormValues,
} from "../../../../../assets/interfaces/Edital";
import styles from "../../../../../common/Cadastro.module.scss";
import { Error } from "../../../../../components/Error";

type Props = {
  touched: FormikTouched<ProdutoFormValues>;
  errors: FormikErrors<ProdutoFormValues>;
};

export default function UnityDropdown({ touched, errors }: Props) {
  const handleChange = () => {
    throw "Unimplemented handle change at UnityDropdown";
  };

  const currentValue = "KG";

  return (
    <>
      <div className={styles.selectGroup}>
        <label htmlFor="unidade">
          Unidade de medida<span>*</span>
        </label>
        <div className={styles.select}>
          <select
            disabled
            name="unidade"
            onChange={handleChange}
            placeholder="Unidade de medida"
            value={currentValue}
          >
            <option>{currentValue}</option>
          </select>
          {/* <div className={styles.selectArrow}></div> */}
        </div>

        {errors.unidade && touched.unidade ? (
          <Error text={errors.unidade} />
        ) : null}
      </div>
    </>
  );
}
