import { createContext, ReactNode } from "react";

import { Edital } from "../assets/interfaces/Edital";
import { Escola } from "../assets/interfaces/Escola";

import { server } from "../services/api";

import { toast } from "react-toastify";

import history from "../routes/history";

interface EscolaContextData {
  cadastrarEscola(dados: Escola): Promise<void>;
  cadastrarEdital(dados: NewEditalData): Promise<void>;
  alterarStatusEdital(dados: Edital, novoStatus: number): Promise<void>;
  buscarEditalPorId(id: string): Promise<Edital | undefined>;
  alterarEdital(dados: editarEdital): Promise<void>;
  removerEdital(id: string): Promise<void>;
  removerCandidatoEdital(id: string): Promise<void>;
  runDSS2(editais: string[], alimentosMatriz: number[][]): Promise<Edital[]>;
  aprovarEdital(id: string): Promise<void>;
  aplicarEdital(candidatura: CandidatarEditalRequest): Promise<void>;
  assinarEdital(id: string): Promise<void>;
  getProductOptions(): Promise<{ label: string; id: string }[]>;
}

interface EscolaProviderProps {
  children: ReactNode;
}

export type RequestedFoodData = {
  id: string;
  quantidade: number;
  valor: number;
  alimentosId: string;
  editaisId: string;
};

export type NewEditalData = {
  nome: string;
  chamada_publica: string;
  numero_processo: string;
  data_inicio: string;
  data_final: string;
  data_inicio_inscricao: string;
  data_fim_inscricao: string;
  data_selecao: string;
  url_arquivo?: string;
  alimentos: RequestedFoodData[];
  entidade: {
    id: string;
  };
};

export type Alimento = {
  id?: string;
  id_edital?: string;
  nome: string;
  unidade: string;
  quantidade: number;
  valor: number;
}

export type editarEdital = {
  id?: string;
  nome: string;
  data_inicio: Date;
  data_final: Date;
  data_inicio_inscricao: Date;
  data_fim_inscricao: Date;
  data_selecao: Date;
  chamada_publica: string;
  numero_processo: string;
  status?: number;
  url_arquivo?: string;
  alimentos: RequestedFoodData[];
}

export type editarAlimentosRequisitados = {
  editaisId?: string;
  alimentosId?: string;
  quantidade: number;
  valor: number;
}

export type CandidatarEditalRequest = {
  editalId : string,
  alimentosSelecionados : string[]
}

export const EscolaContext = createContext({} as EscolaContextData);

export function EscolaProvider({ children }: EscolaProviderProps) {
  async function cadastrarEscola(dados: Escola) {
    try {
      const { data } = await server.post("/entidades", {
        tipo: dados.tipo,
        razao_social: dados.razao_social,
        nome_fantasia: dados.nome_fantasia,
        nome_representante: dados.nome_representante,
        email_representante: dados.email_representante,
        cpf_representante: dados.cpf_representante,
        telefone: dados.telefone,
        cnpj: dados.cnpj,
        cep: dados.cep,
        logradouro: dados.logradouro,
        complemento: dados.complemento,
        bairro: dados.bairro,
        cidade: dados.cidade,
        estado: dados.estado,
        senha: dados.senha,
      });
      if (data) {
        toast("Sua conta foi criada com sucesso!");
        history.push("/escola");
      }
    } catch (error: any) {
      const erro = error.response.data.error;
      toast(erro);
    }
  }

  async function cadastrarEdital(dados: NewEditalData) {
    try {
      const { data } = await server.post("/editais", dados);

      if (data) {
        toast("Edital criado com sucesso!");
        history.push("/escola/editais");
      }
    } catch (error: any) {
      const erro = error.response.data.error;
      toast(erro);
    }
  }

  async function getProductOptions() {
    try {
      const { data } = await server.get("/alimentos");

      const options = data.results.map((product: any) => {
        return {
          label: product.nome,
          id: product.id,
        };
      });

      return options;
    } catch (error: any) {
      const erro = error.response.data.error;
      toast(erro);
    }
  }

  async function alterarStatusEdital(dados: Edital, novoStatus: number) {
    try {
      const { data } = await server.put("/editais", {
        id: dados.id,
        nome: dados.nome,
        chamada_publica: dados.chamada_publica,
        numero_processo: dados.numero_processo,
        data_inicio: dados.data_inicio,
        data_final: dados.data_final,
        data_inicio_inscricao: dados.data_inicio_inscricao,
        data_fim_inscricao: dados.data_fim_inscricao,
        data_selecao: dados.data_selecao,
        status: novoStatus,
        url_arquivo: dados.url_arquivo,
        alimentos: dados.alimentos,
      });

      if (data) {
        toast("Edital alterado com sucesso!");
        window.location.reload();
      }
    } catch (error: any) {
      const erro = error.response.data.error;
      toast(erro);
    }
  }

  async function buscarEditalPorId(id: string) {
    try {
      const { data } = await server.get(`/editais/${id}`);

      const edital: Edital = {
        id: data.id,
        nome: data.nome,
        data_inicio: data.data_inicio,
        data_final: data.data_final,
        data_inicio_inscricao: data.data_inicio_inscricao,
        data_fim_inscricao: data.data_fim_inscricao,
        data_selecao: data.data_selecao,
        chamada_publica: data.chamada_publica,
        numero_processo: data.numero_processo,
        url_arquivo: data.url_arquivo,
        alimentos: data.alimentos,
        status: data.status,
        entidade: data.entidade,
        entidadeCNPJ: data.entidadeCNPJ,
        endereco: data.endereco,
        municipio: data.municipio,
        representanteEntidade: data.representateEntidade,
        cpfRepresentante: data.cpfRepresentante,
        telefone: data.telefone,
      };

      return edital;
    } catch (error: any) {
      console.log(error);
    }
  }

  async function alterarEdital(dados: editarEdital) {
    try {
      const { data } = await server.put("/editais", {
        id: dados.id,
        nome: dados.nome,
        chamada_publica: dados.chamada_publica,
        numero_processo: dados.numero_processo,
        data_inicio: dados.data_inicio,
        data_final: dados.data_final,
        data_fim_inscricao: dados.data_fim_inscricao,
        data_inicio_inscricao: dados.data_inicio_inscricao,
        data_selecao: dados.data_selecao,
        status: dados.status,
        url_arquivo: dados.url_arquivo,
        alimentos: dados.alimentos,
      });

      if (data) {
        toast("Edital alterado com sucesso!");
        history.push("/escola/editais");
      }
    } catch (error: any) {
      const erro = error.response.data.error;
      toast(erro);
    }
  }

  async function removerEdital(id: string) {
    try {
      const response = await server.delete(`/agricultor/editais/${id}`);
      if (response.status === 200) {
        toast("Edital removido com sucesso!");
      }
    } catch (error: any) {
      // erro = error.response.data.error;
      console.log(error);
    }
  }

  async function removerCandidatoEdital(id: string) {
    try {
      const response = await server.delete(`/agricultor/editais/aprovados/${id}`);
      if (response.status === 200) {
        toast("Edital removido com sucesso!");
      }
    } catch (error: any) {
      // erro = error.response.data.error;
      console.log(error);
    }
  }

  async function runDSS2(editais: string[], alimentosMatriz: number[][]) {
    console.log(editais, alimentosMatriz);
    try {
      const res = await server.post("/dss2", {
        alimentosMatriz: alimentosMatriz,
        selecionados: editais.map((edital) => {
          return { editalId: edital };
        }),
      });
      if (res.status === 200) {
        toast("Rodando DSS2 dos editais selecionados");
        return res.data.results;
      }
      //console.log(res.data);
    } catch (err) {
      console.log(err);
    }
  }

  async function aprovarEdital(id: string) {
    try {
      const res = await server.post("/agricultores/editais/ganhos", {
        idEdital: id,
      });
    } catch (err) {
      console.log(err);
    }
  }

  async function aplicarEdital(candidatura: CandidatarEditalRequest) {
    try {
      const res = await server.post("/agricultores/editais/aplicados", {
        candidatura
      });
    } catch (err) {
      console.log(err);
    }
  }

  async function assinarEdital(id: string) {
    try {
      const res = await server.post("/agricultores/editais/assinados", {
        idEdital: id,
      });
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <EscolaContext.Provider
      value={{
        cadastrarEscola,
        cadastrarEdital,
        alterarStatusEdital,
        buscarEditalPorId,
        alterarEdital,
        removerEdital,
        removerCandidatoEdital,
        runDSS2,
        aprovarEdital,
        aplicarEdital,
        assinarEdital,
        getProductOptions,
      }}
    >
      {children}
    </EscolaContext.Provider>
  );
}
