const opcoesProdutos = [
  { label: "ABOBORA CABUTIÁ", id: "03379e8a-effa-4515-a7f6-48729b1d915c" },
  { label: "ABOBRINHA VERDE", id: "3ba5a3f1-f597-467f-88bc-1e139a79e5c0" },
  { label: "AÇAFRÃO", id: "2bd973ff-3fa5-465c-a4d1-398b809b179f" },
  // { label: "ACELGA" },
  // { label: "ALHO" },
  // { label: "BANANA MARMELO" },
  // { label: "BANANA NANICA" },
  // { label: "BANANA PRATA" },
  // { label: "BANANA TERRA" },
  // { label: "BATATA DOCE" },
  // { label: "BETERRABA" },
  // { label: "CEBOLA" },
  // { label: "CENOURA" },
  // { label: "CHUCHU" },
  // { label: "LARANJA" },
  // { label: "MAMÃO FORMOSA" },
  // { label: "MANDIOCA" },
  // { label: "PIMENTÃO" },
  // { label: "REPOLHO" },
];

export { opcoesProdutos };
