import { useEffect, useState } from "react";
import { Calendar, ChevronDown, Link as LinkIcon } from "react-feather";
import Collapsible from "react-collapsible";
import moment from "moment";

import { Header } from "../../../../components/Header";
import { Loader } from "../../../../components/Loader";
import { Status } from "../../../../components/Status";
import FilterBar, {
  FiltrosEditaisAplicadosFormValues,
} from "../../../../components/FilterBar";

import { server } from "../../../../services/api";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";

import emptyImage from "../../../../assets/images/empty.svg";
import "./styles.scss";
import { Edital } from "../../../../assets/interfaces/Edital";

export default function EditaisFechados() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filterValues, setFilterValues] = useState(
    {} as FiltrosEditaisAplicadosFormValues
  );

  const getEditais = async () => {
    var editais:any = await trackPromise(server.get("/agricultores/editais", {params: {status:"assinado"}}), "editais");
    console.log(editais);
    editais = editais.data.results.map((e:any) => e.edital);
    editais = editais.map((edital: Edital) => {
      return {
        ...edital,
        status: 6
      }
    });
    setEditais(editais);
  };

  const [editais, setEditais] = useState<Edital[]>([]);

  const collapsibleEditalProps = {
    classParentString: "edital",
    contentContainerTagName: "div",
    triggerTagName: "div",
    className: "table-body",
    openedClassName: "table-body",
    triggerClassName: "table-row",
    triggerOpenedClassName: "table-row",
  };

  useEffect(() => {
    getEditais();

  }, []);

  const editaisPromise = usePromiseTracker({ area: "editais" });

  return (
    <>
      <Header
        titulo="Editais com contrato fechado"
        descricao="Visão geral de todos os editais com contratos fechados."
      />

      <main id="editais-contrato">
        <div className="editais-contrato-card">
          <Loader area="editais" />

          {editais.length > 0 && editais && (
            <div className="table editais-contrato">
              {/* <FilterBar setFilterFields={setFilterValues}></FilterBar> */}
              <div className="table-head">
                <div className="table-row">

                  <div className="table-headers sm-display">Entidade</div>

                  <div className="table-headers">Município</div>

                  <div className="table-headers sm-display">Data Inicial</div>

                  <div className="table-headers sm-display">Data Final</div>

                  <div className="table-headers">Status</div>

                  <div className="table-headers" aria-hidden="true"></div>
                </div>
              </div>

              {editais &&
                editais.map((edital) => {
                  return (
                    <Collapsible
                      {...collapsibleEditalProps}
                      key={edital.id}
                      trigger={
                        <>
                          <div className="table-column sm-display">
                            {edital.entidade.nome_fantasia}
                          </div>

                          <div className="table-column">{edital.entidade.endereco?.cidade}</div>

                          <div className="table-column sm-display">
                            {moment(edital.data_inicio).format("DD/MM/YYYY")}
                          </div>

                          <div className="table-column sm-display">
                            {moment(edital.data_final).format("DD/MM/YYYY")}
                          </div>

                          <div className="table-column">
                            <Status status={edital.status || 0} />
                          </div>

                          <div className="table-column">
                            <button type="button">
                              <ChevronDown color="#333333" size="1rem" />
                            </button>
                          </div>
                        </>
                      }
                    >
                      <section className="edital-card">
                        <div className="edital-card-header">
                          <div>
                            <h2>
                              {edital.nome}{" "}
                              <Status status={edital.status || 0} />
                            </h2>
                          </div>

                          <h3>Chamada Pública Nº {edital.chamada_publica}</h3>

                          <h4>Processo nº {edital.numero_processo}</h4>
                        </div>

                        <div className="edital-card-body">
                          <div>
                            <h6>Entidade</h6>

                            <p>{edital.entidade.nome_fantasia}</p>
                          </div>

                          <div>
                            <h6>CNPJ da Entidade</h6>

                            <p>{edital.entidade.cnpj}</p>
                          </div>

                          <div>
                            <h6>Endereço</h6>

                            <p>{edital.endereco}</p>
                          </div>

                          <div>
                            <h6>Município/UF</h6>

                            <p>
                              {edital.entidade.endereco?.cidade}/{edital.entidade.endereco?.estado}
                            </p>
                          </div>

                          <div>
                            <h6>Representante da Entidade</h6>

                            <p>{edital.entidade.nome_representante}</p>
                          </div>

                          <div>
                            <h6>Email do Representante da Entidade</h6>

                            <p>{edital.entidade.email_representante}</p>
                          </div>

                          <div>
                            <h6>CPF do Representante da Entidade</h6>

                            <p>{edital.entidade.cpf_representante}</p>
                          </div>

                          <div>
                            <h6>Telefone</h6>

                            <p>{edital.telefone}</p>
                          </div>

                          <div>
                            <h6>Data inicial</h6>

                            <p>
                              <Calendar color="#333333" size="1rem" />
                              {moment(edital.data_inicio).format("DD/MM/YYYY")}
                            </p>
                          </div>

                          <div>
                            <h6>Data de inicio da inscrição</h6>

                            <p>
                              <Calendar color="#333333" size="1rem" />
                              {moment(edital.data_inicio_inscricao).format("DD/MM/YYYY")}
                            </p>
                          </div>

                          <div>
                            <h6>Data final para inscrição</h6>

                            <p>
                              <Calendar color="#333333" size="1rem" />
                              {moment(edital.data_fim_inscricao).format("DD/MM/YYYY")}
                            </p>
                          </div>

                          <div>
                            <h6>Data de seleção dos projetos de venda</h6>

                            <p>
                              <Calendar color="#333333" size="1rem" />
                              {moment(edital.data_selecao).format("DD/MM/YYYY")}
                            </p>
                          </div>

                          <div>
                            <h6>Data final</h6>

                            <p>
                              <Calendar color="#333333" size="1rem" />
                              {moment(edital.data_final).format("DD/MM/YYYY")}
                            </p>
                          </div>

                          {!!edital.url_arquivo && (
                            <div>
                              <h6>URL do edital</h6>

                              <p>
                                <LinkIcon color="#333333" size="1rem" />{" "}
                                <a href={edital.url_arquivo.startsWith('http') ? edital.url_arquivo : "https://"+edital.url_arquivo} target="_blank">
                                        {edital.url_arquivo}
                                      </a>
                              </p>
                            </div>
                          )}

                          <div>
                            <h6>Quantitativo de gêneros alimentícios</h6>

                            {edital.alimentos.length > 0 ? (
                              <table>
                                <thead>
                                  <tr>
                                    <th scope="col">Produto</th>
                                    <th scope="col">Unidade</th>
                                    <th scope="col">Quantidade</th>
                                    <th scope="col">Valor unitário</th>
                                    <th scope="col">Valor total</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {edital.alimentos.map((alimento) => {
                                    return (
                                      <tr key={alimento.nome}>
                                        <td data-label="Produto">
                                          {alimento.nome}
                                        </td>
                                        <td data-label="Unidade">
                                          {alimento.unidade}
                                        </td>
                                        <td data-label="Quantidade">
                                          {alimento.quantidade}
                                        </td>
                                        <td data-label="Valor unitário">
                                          {new Intl.NumberFormat("pt-BR", {
                                            style: "currency",
                                            currency: "BRL",
                                          }).format(
                                            Number(alimento.valor)
                                          )}
                                        </td>
                                        <td data-label="Valor total">
                                          {new Intl.NumberFormat("pt-BR", {
                                            style: "currency",
                                            currency: "BRL",
                                          }).format(
                                            Number(alimento.valor)
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            ) : (
                              <p>
                                Não há gêneros alimentícios cadastrados neste
                                edital.
                              </p>
                            )}
                          </div>
                        </div>
                      </section>
                    </Collapsible>
                  );
                })}
            </div>
          )}

          {!editais.length && !editaisPromise.promiseInProgress && (
            <div className="nenhum-edital">
              <img src={emptyImage} alt="Nenhum edital encontrado" />

              <h1>Não encontramos nenhum edital cadastrado.</h1>
            </div>
          )}
        </div>
      </main>
      <br />
    </>
  );
}
