import { useContext, useState, useEffect } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import { Link, useParams } from 'react-router-dom';

import { initialValues } from './initialValues';
import { validationSchema } from './validationSchema';

import { Header } from '../../../components/Header';

import AgricultorForm from './agricultorForm';

import {
  Agricultor,
  AgricultorCadastroFormValues,
  Associados,
  AssociadosFormValues,
} from '../../../assets/interfaces/Agricultor';

import { AgricultorContext, editarAgricultor } from '../../../contexts/AgricultorContext';

import styles from '../../../common/Cadastro.module.scss';


export default function AgricultorCadastro() {

  const { 
    cadastrarAgricultor, 
    buscarAgricultorPorId, 
    alterarAgricultor,
  } = useContext(AgricultorContext);

  const [agricultor, setAgricultor] = useState<Agricultor | undefined>();
  const [isEditingAgricultor, setIsEditingAgricultor] = useState(false);
  const [associados, setAssociados] = useState<Associados[]>([]);
  const [seedForm, setSeedForm] = useState<AgricultorCadastroFormValues>({...initialValues});

  const { slug }: any = useParams();


  function intoRequestAssociadoData(associado: Associados): AssociadosFormValues{
    return{
      id: associado.id!,
      nome_associado: associado.nome_associado,
      cpf_associado: associado.cpf_associado,
      caf_associado: associado.caf_associado,
      municipio_associado: associado.municipio_associado,
      estado_associado: associado.estado_associado,
      associado_priorizado: associado.associado_priorizado
    }
  }

  function composeAgricultor(values: AgricultorCadastroFormValues){
    const agricultor: Agricultor = {
      nome_proponente: values.nome_proponente,
      tipo: values.tipo,
      email: values.email,
      cpf: values.cpf,
      cnpj: values.cnpj,
      telefone: values.telefone || '',
      celular: values.celular,
      dap: values.dap,
      cep: values.cep,
      logradouro: values.logradouro || '',
      complemento: values.complemento || '',
      bairro: values.bairro || '',
      cidade: values.cidade,
      estado: values.estado,
      area_produzida: values.area_produzida,
      banco: values.banco,
      conta_bancaria: values.conta_bancaria,
      agencia: values.agencia,
      operacao: values.operacao || '',
      senha: values.senha,
      numero_associados: values.numero_associados,
      associados_cpf: values.associados_cpf,
      numero_associados_lei: values.numero_associados_lei || 0,
      numero_associados_dap: values.numero_associados_dap || 0,
      representante_legal: values.representante_legal || '',
      representante_cpf: values.representante_cpf || '',
      ic_priorizado: values.ic_priorizado,
      associados: values.associados && Array.isArray(values.associados) ? values.associados.map(intoRequestAssociadoData) : [],

    };
    return agricultor;
  }

  const handleSubmitForm = async (
    values: AgricultorCadastroFormValues,
    actions: FormikHelpers<AgricultorCadastroFormValues>
  ) => {
    
    actions.validateForm();
    // actions.resetForm();
    const novoAgricultor = composeAgricultor(values);
    
    if(!slug) {
      cadastrarAgricultor(novoAgricultor);
    }else {
      if(agricultor){
        const agricultorEditado: editarAgricultor = {
          id: slug,
          tipo: values.tipo,
          nome_proponente: values.nome_proponente,
          email: values.email,
          cpf: values.cpf,
          cnpj: values.cnpj,
          telefone: values.telefone || '',
          celular: values.celular,
          dap: values.dap,
          cep: values.cep,
          logradouro: values.logradouro,
          complemento: values.complemento,
          bairro: values.bairro,
          cidade: values.cidade,
          estado: values.estado,
          area_produzida: values.area_produzida,
          banco: values.banco,
          conta_bancaria: values.conta_bancaria,
          agencia: values.agencia,
          operacao: values.operacao,
          senha: values.senha,
          numero_associados: values.numero_associados,
          numero_associados_lei: values.numero_associados_lei,
          numero_associados_dap: values.numero_associados_dap,
          representante_legal: values.representante_legal,
          representante_cpf: values.representante_cpf,
          ic_priorizado: values.ic_priorizado,
          //associados_cpf: values.associados_cpf,
          associados: associados.map(intoRequestAssociadoData)
        }
        alterarAgricultor(agricultorEditado)
      }else {
        throw "Agricultor não encontrado"
      }
    }
    
  }

  return (
    <>
      <Header
        titulo='Assistente PNAE'
        descricao={isEditingAgricultor ? 'Atualize suas informações cadastrais' :'Faça seu cadastro para ter acesso a plataforma.'}
      />

      <main className={styles.cadastro}>
        <div className={styles.formCard}>
          <h2>Agricultor e fornecedor</h2>

          { !isEditingAgricultor ? (
              <p>
                Já possui uma conta?{' '}
                <Link to='/agricultor/acessar'>Entre agora</Link>
              </p>
          ) : null}

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmitForm}
          >
            {({ errors, touched, handleSubmit }) => (
              <AgricultorForm/>
            )}
          </Formik>
        </div>
      </main>
      <br />
    </>
  );
}
