import { InputHTMLAttributes, ReactNode } from "react";

import InputMask from "react-input-mask";

import { HelpCircle } from "react-feather";

import { Tooltip } from "react-tippy";

import styles from "./Input.module.scss";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  children: ReactNode;
  helperText?: string | null;
  isRequired?: boolean;
  label?: string;
  mask?: string | (string | RegExp)[] | null;
  name: string;
}

export function Input({
  children,
  helperText = null,
  isRequired = false,
  label = "",
  mask = null,
  name,
  ...rest
}: InputProps) {
  return (
    <div className={styles.inputGroup}>
      <label htmlFor={name} className={!!label ? styles.containLabel : ""}>
        {label}
        {isRequired && <span>*</span>}
        {helperText && (
          <Tooltip
            animation={"fade"}
            arrow={true}
            interactive={true}
            position="right"
            title={helperText}
            trigger={"mouseenter"}
          >
            <button className={styles.helperText} type="button">
              <HelpCircle color="#B3B3B3" size="1rem" />
            </button>
          </Tooltip>
        )}
      </label>

      {mask ? (
        <InputMask
          id={name}
          mask={mask}
          name={name}
          placeholder={label}
          {...rest}
        />
      ) : (
        <input id={name} name={name} placeholder={label} {...rest} />
      )}

      <>{children}</>
    </div>
  );
}
