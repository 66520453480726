import {useState, useEffect, useContext} from "react";
import {
    Calendar,
    ChevronDown,
    DollarSign,
    Edit3,
    Trash,
    Link as LinkIcon,
  } from "react-feather";
import Collapsible from "react-collapsible";
import moment from "moment";
import Swal from "sweetalert2";
import { Header } from "../../../components/Header";
import './styles.scss';
import { Edital } from "../../../assets/interfaces/Edital";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { server } from "../../../services/api";
import { AuthContext } from "../../../contexts/AuthContext";
import { Loader } from "../../../components/Loader";
import emptyImage from "../../../assets/images/empty.svg";
import { Status } from "../../../components/Status";
import { EscolaContext } from "../../../contexts/EscolaContext";
import { Agricultor } from "../../../assets/interfaces/Agricultor";


export default function PropostasCadastradas(){
    const [table, setTable] = useState(0)
    const [editais, setEditais] = useState<Edital[]>([]);
    console.log(table);
    const { usuario } = useContext(AuthContext);
    const [agricultores,setAgricultores] = useState<Agricultor[]>([]);

    const { alterarStatusEdital, removerEdital } = useContext(EscolaContext);

    const collapsibleEditalProps = {
        classParentString: "edital",
        contentContainerTagName: "div",
        triggerTagName: "div",
        className: "table-body",
        openedClassName: "table-body",
        triggerClassName: "table-row",
        triggerOpenedClassName: "table-row",
      };

    useEffect(() => {
        const getEditais = async () => {
          const { data } = await trackPromise(
            server.get("/editais", { params: { entidadeId: usuario!.id } }),
            "editais"
          );
    
          if (data.results) {
            setEditais(data.results);
          }
        };
    
        getEditais();
      }, []);
      const editaisPromise = usePromiseTracker({ area: "editais" });

    return(
        <>
        <Header
            titulo='Assistente PNAE'
            descricao='Uma visão geral das propostas aceitas pelos agricultores.'
        />
        <main id="contrato">
          <div className={'contrato-card'}>
            <div className="select">
              <h3>Ordernar por:</h3>
              <label className="input-custom">
                <input
                  type='radio'
                  name='tipo'
                  value={0}
                  onClick={() => setTable(0)}
                  checked={Number(table)===0}
                />
                <span>Produto</span>
              </label>
              <label className="input-custom">
                <input
                  type='radio'
                  name='tipo'
                  value={1}
                  onClick={() => setTable(1)}
                />
                <span>Agricultor</span>
              </label>
            </div>
              {editais.length > 0 && editais && (
                <div>
                  <div>
                    {table === 0 && (
                      <table>
                  {editais &&
                  editais.map((edital) => {
                    return (
                      <div className="contrato-container">
                        <section className="contrato-card">
                          <div>
                          <div className="contrato-card-header">
                            <div>
                              <h2>
                                {edital.nome}{" "}
                                <Status status={edital.status || 0} />
                              </h2>
                            </div>

                            <h3>Chamada Pública Nº {edital.chamada_publica}</h3>

                            <h4>Processo nº {edital.numero_processo}</h4>
                          </div>
                          <div className="table contrato">
                          <div className="table-head">
                            <div className="table-row">
                              <div className="table-headers">Produto</div>

                              <div className="table-headers sm-display">
                                  Quantidade
                              </div>

                              <div className="table-headers sm-display">Unidade</div>

                              <div className="table-headers sm-display">Valor Unitário</div>

                              <div className="table-headers sm-display">Valor total</div>

                              <div className="table-headers" aria-hidden="true"></div>
                            </div>
                          </div>
                          </div>
                          </div>
                          {editais.length > 0 && editais && (
                            <div className="table contrato">
                            {/* <FilterBar setFilterFields={setFilterValues}></FilterBar> */}

                              {edital.alimentos.map((alimento) => {
                                return (
                                  <>
                                  <div className="edital-container">

                                    {edital.alimentos.length > 0 ? (
                                      <div className="table-user">
                                        <Collapsible
                                          {...collapsibleEditalProps}
                                          key={edital.id}
                                          trigger={
                                          <>
                                            <div className="contrato-table-column">
                                                  {alimento.nome}
                                            </div>
                                            <div className="contrato-table-column ">
                                              {alimento.quantidade}
                                            </div>

                                            <div className="contrato-table-column ">
                                              {alimento.unidade}
                                            </div>

                                            <div className="contrato-table-column ">
                                              {new Intl.NumberFormat("pt-BR", {
                                                style: "currency",
                                                currency: "BRL",
                                              }).format(Number(alimento.valor))}
                                            </div>
                                            <div className="contrato-table-column ">
                                              {new Intl.NumberFormat("pt-BR", {
                                                style: "currency",
                                                currency: "BRL",
                                              }).format(
                                                Number(
                                                  alimento.valor *
                                                  alimento.quantidade
                                                )
                                              )}
                                            </div>
                                            <div className="contrato-table-column">
                                            <div className="table-colum">
                                              <button type='button'>
                                                <ChevronDown color="#333333" size='1rem'></ChevronDown>
                                              </button>
                                            </div>
                                            </div>
                                          </>
                                          }
                                        >
                                          <section className="edital-card">
                                            <div className="dados-table">
                                              <h2>
                                                Agricultor 1
                                              </h2>

                                              <div className="dados-card-body">
                                              <div className="component">
                                                  <h6>Quantidade Contratada</h6>

                                                  <p>
                                                    111
                                                  </p>
                                                </div>
                                                <div className="component">
                                                  <h6>CAF</h6>

                                                  <p>
                                                    000000000000CAF
                                                  </p>
                                                </div>
                                                <div className="component">
                                                  <h6>Cpf</h6>

                                                  <p>
                                                    222.222.222-22
                                                  </p>
                                                </div>
                                                <div className="component">
                                                  <h6>Email</h6>

                                                  <p>
                                                    teste@email.com
                                                  </p>
                                                </div>
                                                <div className="component">
                                                  <h6>Telefone</h6>

                                                  <p>
                                                    (62)9999-9999
                                                  </p>
                                                </div>
                                                <div className="component">
                                                  <h6>Celular</h6>

                                                  <p>
                                                    (62)9999-9999
                                                  </p>
                                                </div>
                                              </div>
                                              <div className="titulo">
                                                <h6>Dados Bancários</h6>
                                              </div>
                                              <div className="table-banco">
                                                <div className="component">
                                                  <h6>Banco</h6>

                                                  <p>
                                                    Banco Inter
                                                  </p>
                                                </div>
                                                <div className="component">
                                                  <h6>Agencia</h6>

                                                  <p>
                                                    0001
                                                  </p>
                                                </div>
                                                <div className="component">
                                                  <h6>Conta</h6>

                                                  <p>
                                                    000000-2
                                                  </p>
                                                </div>
                                                <div className="component">
                                                  <h6>Operação</h6>

                                                  <p>
                                                    1
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </section>
                                        </Collapsible>
                                      </div>
                                    ) : (
                                      <p>
                                        Não há gêneros alimentícios cadastrados neste
                                        edital.
                                      </p>
                                      
                                    )}

                                  </div>
                                  
                                  </>
                                );
                              })}
                            </div>
                            )}
                        </section>
                      </div>
                    );
                  })}
                    </table>
                    )}
                  </div>

                  <div>
                    {table === 1 && (
                      <table>
                  {editais &&
                  editais.map((edital) => {
                    return (
                      <div className="contrato-container">
                        <section className="contrato-card">
                          <div>
                          <div className="contrato-card-header">
                            <div>
                              <h2>
                                {edital.nome}{" "}
                                <Status status={edital.status || 0} />
                              </h2>
                            </div>

                            <h3>Chamada Pública Nº {edital.chamada_publica}</h3>

                            <h4>Processo nº {edital.numero_processo}</h4>
                          </div>
                          <div className="table contrato">
                          <div className="table-head">
                            <div className="table-row">
                              <div className="table-headers">Agricultor</div>

                              <div className="table-headers sm-display">
                                  CPF
                              </div>

                              <div className="table-headers sm-display">CAF</div>

                              <div className="table-headers sm-display">Telefone</div>

                              <div className="table-headers sm-display">Valor total</div>

                              <div className="table-headers" aria-hidden="true"></div>
                            </div>
                          </div>
                          </div>
                          </div>
                          {editais.length > 0 && editais && (
                            <div className="table contrato">
                            {/* <FilterBar setFilterFields={setFilterValues}></FilterBar> */}

                              {edital.alimentos.map((alimento) => {
                                return (
                                  <>
                                  <div className="edital-container">

                                    {edital.alimentos.length > 0 ? (
                                      <div className="table-user">
                                        <Collapsible
                                          {...collapsibleEditalProps}
                                          key={edital.id}
                                          trigger={
                                          <>
                                            <div className="contrato-table-column">
                                                  Agricultor 1
                                            </div>
                                            <div className="contrato-table-column ">
                                              {alimento.quantidade}
                                            </div>

                                            <div className="contrato-table-column ">
                                              {alimento.unidade}
                                            </div>

                                            <div className="contrato-table-column ">
                                              {new Intl.NumberFormat("pt-BR", {
                                                style: "currency",
                                                currency: "BRL",
                                              }).format(Number(alimento.valor))}
                                            </div>
                                            <div className="contrato-table-column ">
                                              {new Intl.NumberFormat("pt-BR", {
                                                style: "currency",
                                                currency: "BRL",
                                              }).format(
                                                Number(
                                                  alimento.valor *
                                                  alimento.quantidade
                                                )
                                              )}
                                            </div>
                                            <div className="contrato-table-column">
                                            <div className="table-colum">
                                              <button type='button'>
                                                <ChevronDown color="#333333" size='1rem'></ChevronDown>
                                              </button>
                                            </div>
                                            </div>
                                          </>
                                          }
                                        >
                                          <section className="edital-card">
                                            <div className="dados-table">
                                              <h2>
                                                Agricultor 1
                                              </h2>

                                              <div className="table contrato">
                                              <div className="table-head">
                                                <div className="table-row">
                                                  <div className="table-headers">Produto</div>

                                                  <div className="table-headers sm-display">
                                                      Quantidade
                                                  </div>

                                                  <div className="table-headers sm-display">Unidade</div>

                                                  <div className="table-headers sm-display">Valor Unitário</div>

                                                  <div className="table-headers sm-display">Valor total</div>

                                                  <div className="table-headers" aria-hidden="true"></div>
                                                </div>
                                              </div>
                                              </div>

                                            <div className="table-row">
                                            <div className="contrato-table-column">
                                                  {alimento.nome}
                                            </div>
                                            <div className="contrato-table-column ">
                                              {alimento.quantidade}
                                            </div>

                                            <div className="contrato-table-column ">
                                              {alimento.unidade}
                                            </div>

                                            <div className="contrato-table-column ">
                                              {new Intl.NumberFormat("pt-BR", {
                                                style: "currency",
                                                currency: "BRL",
                                              }).format(Number(alimento.valor))}
                                            </div>
                                            <div className="contrato-table-column ">
                                              {new Intl.NumberFormat("pt-BR", {
                                                style: "currency",
                                                currency: "BRL",
                                              }).format(
                                                Number(
                                                  alimento.valor *
                                                  alimento.quantidade
                                                )
                                              )}
                                            </div></div>

                                              <div className="dados-card-body">
                                                <h3>Informações Pessoais</h3>
                                                <h3> </h3>
                                                <div className="component">
                                                  <h6>Cpf</h6>

                                                  <p>
                                                    222.222.222-22
                                                  </p>
                                                </div>
                                                <div className="component">
                                                  <h6>Email</h6>

                                                  <p>
                                                    teste@email.com
                                                  </p>
                                                </div>
                                                <div className="component">
                                                  <h6>Telefone</h6>

                                                  <p>
                                                    (62)9999-9999
                                                  </p>
                                                </div>
                                                <div className="component">
                                                  <h6>Celular</h6>

                                                  <p>
                                                    (62)9999-9999
                                                  </p>
                                                </div>
                                              </div>
                                              <div className="titulo">
                                                <h6>Dados Bancários</h6>
                                              </div>
                                              <div className="table-banco">
                                                <div className="component">
                                                  <h6>Banco</h6>

                                                  <p>
                                                    Banco Inter
                                                  </p>
                                                </div>
                                                <div className="component">
                                                  <h6>Agencia</h6>

                                                  <p>
                                                    0001
                                                  </p>
                                                </div>
                                                <div className="component">
                                                  <h6>Conta</h6>

                                                  <p>
                                                    000000-2
                                                  </p>
                                                </div>
                                                <div className="component">
                                                  <h6>Operação</h6>

                                                  <p>
                                                    1
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </section>
                                        </Collapsible>
                                      </div>
                                    ) : (
                                      <p>
                                        Não há gêneros alimentícios cadastrados neste
                                        edital.
                                      </p>
                                      
                                    )}

                                  </div>
                                  
                                  </>
                                );
                              })}
                            </div>
                            )}
                        </section>
                      </div>
                    );
                  })}
                    </table>
                    )}
                  </div>
                </div>
              )}
              

              {!editais.length && !editaisPromise.promiseInProgress && (
                <div className="nenhum-edital">
                  <img src={emptyImage} alt="Nenhum edital encontrado" />

                  <h1>Não encontramos nenhuma proposta cadastrada.</h1>
                </div>
              )}
 
          </div>  
            
                      
        </main>
        </>
    )
}