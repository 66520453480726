import React from "react";
import { Dropdown } from "primereact/dropdown";

import styles from "../../../../common/Cadastro.module.scss";
import { FormikErrors, FormikTouched } from "formik";
import "./custom_dropdown.css";
import { CadastroProducaoFormValues } from "../../../../assets/interfaces/Agricultor";

type Props<T> = {
  options: { label: String; id: string }[];
  label: string;
  touched: FormikTouched<CadastroProducaoFormValues>;
  errors: FormikErrors<CadastroProducaoFormValues>;
  changeCallback: (event: any) => void;
  value: T;
};

export default function ProducaoDropdown<T>({
  label,
  touched,
  errors,
  options,
  changeCallback,
  value,
}: Props<T>) {
  return (
    <>
      <div className={styles.selectGroup}>
        <label htmlFor="unidade">
          {label}
          <span>*</span>
        </label>
        <div className={styles.select}>
          <Dropdown
            id="produto"
            className="customdropdown"
            filter
            options={options}
            onChange={(event) => changeCallback(event)}
            value={value}
            optionValue="id"
          ></Dropdown>
        </div>
      </div>
    </>
  );
}
