import axios from "axios";
import Cookies from "js-cookie";

const viacep = axios.create({
  baseURL: "https://viacep.com.br/ws/",
});

let cookies = Cookies.get();

const baseUrl = process.env.REACT_APP_API_BASE_URL ?? '';

const server = axios.create({
  baseURL:  baseUrl,
  headers: {
    Authorization: `Bearer ${cookies["auth.token"]}`,
  },
});

const mockserver = axios.create({
  baseURL: "https://62abb104bd0e5d29af14202a.mockapi.io/",
});

export { viacep, server, mockserver };
