import React from "react";
import { Form, Formik } from "formik";

import { Input } from "../Input";

import initialValues from "./initialValues";
import validationSchema from "./validationSchema";
import styles from "./FilterBar.module.scss";
import { MultiSelect } from "primereact/multiselect";
import FilterButton from "../FIlterButton";

export interface FiltrosEditaisAplicadosFormValues {
  busca: string;
  filtro1: string;
  filtro2: string;
}

interface FiltrosEditaisAplicadosProps {
  setFilterFields: React.Dispatch<
    React.SetStateAction<FiltrosEditaisAplicadosFormValues>
  >;
  setSelectedFilterProducts: React.Dispatch<React.SetStateAction<any>>;
  selectedFilterProducts: any;
  productsFilterOptions: any; // TODO specify type
}

export default function FilterBar({
  setFilterFields,
  setSelectedFilterProducts,
  selectedFilterProducts,
  productsFilterOptions,
}: FiltrosEditaisAplicadosProps) {
  const handleSearch = async (values: FiltrosEditaisAplicadosFormValues) => {
    setFilterFields(values);
    return;
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSearch}
    >
      {({ values, handleChange }) => (
        <Form className={styles.filters}>
          <div className={styles.filterContainer}>
            <Input
              name="busca"
              placeholder="Buscar..."
              onChange={handleChange}
              type="text"
              value={values.busca}
            >
              <></>
            </Input>

            <MultiSelect
              value={selectedFilterProducts}
              options={productsFilterOptions}
              onChange={(e) => setSelectedFilterProducts(e.value)}
              placeholder="Selecione os produtos para filtrar"
              filter
              className="multiselect-custom"
              display="chip"
            />

            <FilterButton onClick={() => {}} />
          </div>
        </Form>
      )}
    </Formik>
  );
}
