import { FocusEvent, useContext, useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useFormikContext } from 'formik';

import { Error } from '../../../components/Error';
import { Input } from '../../../components/Input';
import styles from '../../../common/Cadastro.module.scss';


interface infosPessoaisValues {
  tipo: number;
  nome_proponente: string;
  email: string;
  cpf: string;
  cnpj: string;
  celular: string;
  telefone: string;
  representante_legal: string;
  representante_cpf: string;
  dap: string;
  ic_priorizado: string;
}


export default function InfosPessoaisAgricultor({isEdditing} : {isEdditing: boolean}) {
  const { values, errors, touched, handleChange, setFieldValue } = useFormikContext<infosPessoaisValues>();
  
  const [isExibirRepresentanteLegal, setIsExibirRepresentanteLegal] = useState(false);
  const [isExibirCpf, setIsExibirCpf] = useState(false);
  const [isExibirCnpj, setIsExibirCnpj] = useState(false);
  const [isExibirCaf, setIsExibirCaf] = useState(false);
  const [isExibirIcPriorizado, setIsExibirIcPriorizado] = useState(false);

  function configuraExibicaoCampos(value: string) {
    // reseta exibição dos campos
    setIsExibirRepresentanteLegal(false);
    setIsExibirCpf(false);
    setIsExibirCnpj(false);
    setIsExibirCaf(false);
    setIsExibirIcPriorizado(false);
    
    // define quais campos serao exibidos
    if(value == "1") {
      // individual
      setIsExibirCpf(true);
      setIsExibirCaf(true);
      setIsExibirIcPriorizado(true);
    } else if(value == "2") {
      // formal
      setIsExibirCnpj(true);
      setIsExibirCaf(true);
      setIsExibirRepresentanteLegal(true);
      
    } else if(value == "3") {
      // informal
      setIsExibirRepresentanteLegal(true);
    }
  }

  useEffect(() => {
    const value = values.tipo;
    configuraExibicaoCampos(`${value}`);
  }, [])

  function handleTipoCadastro(event: FocusEvent<HTMLInputElement>) {
    const { value } = event.target;
    configuraExibicaoCampos(value);
    setFieldValue("tipo", value);
  }
    
  return (
    <>
      <h3>Informações pessoais</h3>
      {!isEdditing ? (
          <div className={styles.radioGroup}>
            <label className='custom-input-container'>
              <span>Individual</span>
    
              <input
                type='radio'
                name='tipo'
                value={1}
                onChange={handleTipoCadastro}
                checked={Number(values.tipo) === 1}
              />
    
              <div className='custom-input'></div>
            </label>
    
            <label className='custom-input-container'>
              <span>Grupo Informal</span>
    
              <input
                type='radio'
                name='tipo'
                value={3}
                onChange={handleTipoCadastro}
                checked={Number(values.tipo) === 3}
              />
    
              <div className='custom-input'></div>
            </label>
    
            <label className='custom-input-container'>
              <span>Grupo Formal</span>
    
              <input
                type='radio'
                name='tipo'
                value={2}
                onChange={handleTipoCadastro}
                checked={Number(values.tipo) === 2}
              />
    
              <div className='custom-input'></div>
            </label>
          </div>
        ) : null
      }
                   
      <div className={styles.formGroup}>

        <Input
          isRequired
          label='Nome'
          name='nome_proponente'
          onChange={handleChange}
          type='text'
          value={values.nome_proponente}
          placeholder='Escreva seu nome'
        >
          {errors.nome_proponente && touched.nome_proponente ? (
            <Error text={errors.nome_proponente} />
          ) : null}
        </Input>

        <Input
          isRequired
          label='Email'
          name='email'
          onChange={handleChange}
          type='text'
          value={values.email}
          placeholder='Insira um email válido'
        >
          {errors.email && touched.email ? (
            <Error text={errors.email} />
          ) : null}
        </Input>

        { isExibirCpf ? (
            <Input
              isRequired
              label='CPF'
              mask='999.999.999-99'
              name='cpf'
              onChange={handleChange}
              type='text'
              value={values.cpf}
              placeholder='Insira seu CPF'
            >
              {errors.cpf && touched.cpf ? (
                <Error text={errors.cpf} />
              ) : null}
            </Input>
          ) : null
        }

        { isExibirCnpj ? (
            <Input
              isRequired
              label='CNPJ'
              mask='99.999.999/9999-99'
              name='cnpj'
              onChange={handleChange}
              type='text'
              value={values.cnpj}
              placeholder='Insira o CNPJ'
            >
              {errors.cnpj && touched.cnpj ? (
                <Error text={errors.cnpj} />
              ) : null}
            </Input>
          ) : null
        }
        
        { isExibirCaf ? (
            <Input
              isRequired
              label='CAF'
              name='dap'
              onChange={handleChange}
              type='text'
              value={values.dap}
              placeholder='Insira o número do CAF'
              helperText='Insira o numero do cadastro da agriltura familiar'
            >
              {errors.dap && touched.dap ? (
                <Error text={errors.dap} />
              ) : null}
            </Input>
          ) : null
        }

        { isExibirRepresentanteLegal ? (
            <>
              <Input
                isRequired
                label={'Nome do representante' + `${values?.tipo == 2 ? " Legal" : ""}`}
                name='representante_legal'
                onChange={handleChange}
                type='text'
                value={values.representante_legal}
                placeholder='Insira o nome do representante legal'
              >
                {errors.representante_legal && touched.representante_legal ? (
                  <Error text={errors.representante_legal} />
                ) : null}
              </Input>
          
              <Input
                isRequired
                label={'CPF do representante' + `${values?.tipo == 2 ? " Legal" : ""}`}
                mask='999.999.999-99'
                name='representante_cpf'
                onChange={handleChange}
                type='text'
                value={values.representante_cpf}
                placeholder='Insira o CPF do representante'
              >
                {errors.representante_cpf &&
                touched.representante_cpf ? (
                  <Error text={errors.representante_cpf} />
                ) : null}
              </Input>
            </>
          ) : null
        }

        <Input
          isRequired
          label='Celular'
          mask='(99) 99999-9999'
          name='celular'
          onChange={handleChange}
          type='text'
          value={values.celular}
          placeholder='Insira seu número'
        >
          {errors.celular && touched.celular ? (
            <Error text={errors.celular} />
          ) : null}
        </Input>

        <Input
          label='Telefone'
          mask='(99) 9999-9999'
          name='telefone'
          onChange={handleChange}
          type='text'
          value={values.telefone}
          placeholder='Insira seu número'
        >
          {errors.telefone && touched.telefone ? (
            <Error text={errors.telefone} />
          ) : null}
        </Input>

        { isExibirIcPriorizado ? (
            <div className={styles.plantedContainer}>
              <label className='planted-label'>É um grupo priorizado?</label>
              <select
                className='planted-checkbox'
                value={values.ic_priorizado? 'sim' : 'nao'}
                onChange={(event) => {
                  const valor = event.target.value === 'sim' ? true : event.target.value === 'nao' ? false : undefined;
                  setFieldValue('ic_priorizado', valor);
                }}
              >
                <option value="selecione">Selecione</option>
                <option value="sim">Sim</option>
                <option value="nao">Não</option>
              </select>
            </div>
          ) : null
        }

      </div>
    </>
  );
}
