import { useContext, useEffect, useState } from 'react';

import { Formik, Form, FormikHelpers } from 'formik';

import { initialValues } from './initialValues';
import { validationSchema } from './validationSchema';

import { Header } from '../../../components/Header';
import { Error } from '../../../components/Error';
import { Input } from '../../../components/Input';

import { AuthContext } from "../../../contexts/AuthContext";

import {
  CadastroInsumoFormValues,
  Insumo,
} from '../../../assets/interfaces/Agricultor';

import styles from '../../../common/Cadastro.module.scss';
import history from '../../../routes/history';

import { AgricultorContext } from '../../../contexts/AgricultorContext';
import { useParams } from 'react-router-dom';

export default function CadastroInsumo() {
  const { usuario } = useContext(AuthContext); //Pega os dados do usuário logado
  const { cadastrarInsumo, buscarInsumoPorId, alterarInsumo } =
    useContext(AgricultorContext);

  const [insumo, setInsumo] = useState<Insumo | undefined>();
  const [isEditingInsumo, setIsEditingInsumo] = useState(false);

  let { slug }: any = useParams();
  useEffect(() => {
    const getEdital = async () => {
      setInsumo(await buscarInsumoPorId(slug));
    };

    if (slug) {
      getEdital();
      setIsEditingInsumo(true);
    }
  }, [buscarInsumoPorId, slug]);

  async function handleSubmitForm(
    values: CadastroInsumoFormValues,
    actions: FormikHelpers<CadastroInsumoFormValues>
  ) {
    actions.validateForm();

    if (!slug) {
        await cadastrarInsumo({
        descricao: values.descricao,
        custo_unitario: values.custo_unitario,
        id_categoria: values.id_categoria,
        unidade: values.unidade,
        id_agricultor: usuario!.id,
      });
    } else {
      const insumoEditado: Insumo = {
        id: insumo?.id,
        descricao: values.descricao,
        custo_unitario: values.custo_unitario,
        id_categoria: values.id_categoria,
        unidade: values.unidade,
        id_agricultor: usuario!.id,
      }
      await alterarInsumo(insumoEditado);
    }
  }

  return (
    <>
      <Header
        titulo={isEditingInsumo ? 'Editar insumo' : 'Cadastrar insumo'}
        descricao='Informe os dados do insumo no formulário abaixo.'
      />

      <main className={styles.cadastro}>
        <div className={styles.formCard}>
          <h2>{isEditingInsumo ? 'Editar insumo' : 'Cadastrar insumo'}</h2>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmitForm}
          >
            {function ShowForm({
              touched,
              values,
              errors,
              handleChange,
              setFieldValue,
            }) {
              useEffect(() => {
                if (insumo) {
                  setFieldValue('descricao', insumo.descricao);
                  setFieldValue('id_categoria', insumo.id_categoria);
                  setFieldValue('unidade', insumo.unidade);
                  setFieldValue('custo_unitario', insumo.custo_unitario);
                }
                // eslint-disable-next-line react-hooks/exhaustive-deps
              }, [insumo]);

              return (
                <Form>
                  <section>
                    <h3>Dados</h3>

                    <div className={styles.formGroup}>
                      <Input
                        isRequired
                        label='Descrição'
                        name='descricao'
                        onChange={handleChange}
                        type='text'
                        value={values.descricao}
                      >
                        {errors.descricao && touched.descricao ? (
                          <Error text={errors.descricao} />
                        ) : null}
                      </Input>

                      <div className={styles.selectGroup}>
                        <label htmlFor='id_categoria'>
                          Categoria<span>*</span>
                        </label>
                        <div className={styles.select}>
                          <select
                            name='id_categoria'
                            onChange={handleChange}
                            placeholder='Categoria'
                            value={values.id_categoria}
                          >
                            <option value='0' key='0'>
                              Categoria
                            </option>
                            <option value='1' key='1'>
                              Maquinário
                            </option>
                            <option value='2' key='2'>
                              Fertilizantes
                            </option>
                            <option value='3' key='3'>
                              Inseticida
                            </option>
                            <option value='4' key='4'>
                              Processamento
                            </option>
                            <option value='5' key='5'>
                              Embalagem
                            </option>
                            <option value='6' key='6'>
                              Mão de obra
                            </option>
                            <option value='7' key='7'>
                              Herbicidas
                            </option>
                            <option value='8' key='8'>
                              Sementes/Mudas/Ramas
                            </option>
                          </select>
                          <div className={styles.selectArrow}></div>
                        </div>

                        {errors.id_categoria && touched.id_categoria ? (
                          <Error text={errors.id_categoria} />
                        ) : null}
                      </div>

                      <Input
                        isRequired
                        label='Unidade'
                        name='unidade'
                        onChange={handleChange}
                        type='number'
                        value={values.unidade}
                        min={0}
                      >
                        {errors.unidade && touched.unidade ? (
                          <Error text={errors.unidade} />
                        ) : null}
                      </Input>

                      <Input
                        isRequired
                        label='Custo unitário'
                        name='custo_unitario'
                        onChange={handleChange}
                        type='number'
                        step={0.01}
                        min={0}
                        value={values.custo_unitario}
                      >
                        {errors.custo_unitario && touched.custo_unitario ? (
                          <Error text={errors.custo_unitario} />
                        ) : null}
                      </Input>
                    </div>
                  </section>

                  <section>
                    <div className={styles.formGroup}>
                      <button
                        className={styles.cancel}
                        onClick={() => history.push('/agricultor/insumos')}
                      >
                        Cancelar
                      </button>
                      <button type='submit'>
                        {isEditingInsumo
                          ? 'Finalizar edição do insumo'
                          : 'Finalizar cadastro do insumo'}
                      </button>
                    </div>
                  </section>

                  <section>
                    <div className={styles.formGroup}>
                      <span></span>
                    </div>
                  </section>
                </Form>
              );
            }}
          </Formik>
        </div>
      </main>
      <br />
    </>
  );
}
