import { useContext, useState, useEffect } from 'react';
import { Formik, Form, FormikHelpers, useFormikContext, Field,FieldArray } from 'formik';
import { Link, useParams } from 'react-router-dom';
import { Trash2 } from 'react-feather';

import { initialValues } from './initialValues';

import AssociadoComponent from './associadoComponent';

import {
  Agricultor,
  AgricultorCadastroFormValues,
  Associados,
  AssociadosFormValues,
} from '../../../assets/interfaces/Agricultor';

import { Error } from '../../../components/Error';
import { Input } from '../../../components/Input';

import { AgricultorContext, editarAgricultor } from '../../../contexts/AgricultorContext';

import styles from '../../../common/Cadastro.module.scss';



export default function AssociadosForm({isEdditing} : {isEdditing: boolean}) {

  const { values, errors, touched, handleChange, setFieldValue } = useFormikContext<any>();

  function renderAssociados({ insert, remove, push } : any) {
    if (!Array.isArray(values.associados) ) return <></>
    if (values.associados.length <= 0) return <></>

    return values.associados?.map((_ : any, index: number) => (
      <AssociadoComponent 
        key={index}
        index={index} 
        remove={remove}
      /> 
    ))
  }

  return (
    <>
      <h3>Associados</h3>

      <FieldArray name="associados">

        {
          ({ insert, remove, push }) => (
            <>
              <>
                {renderAssociados({insert, remove, push})}
              </>

              <div className={styles.secondaryDiv}>
                <button
                  type="button"
                  className= {styles.secondary}
                  onClick={() => {
                    const associadoNumber = values.numero_associados ? Number(values.numero_associados) : 0;
                    const newValue = associadoNumber ? associadoNumber + 1 : 1;
                    setFieldValue('numero_associados', newValue);
                    
                    push({
                      id: newValue,
                      nome_associado: '',
                      cpf_associado: '',
                      caf_associado: '',
                      municipio_associado: '',
                      estado_associado: '',
                      associado_priorizado: true
                    });

                  }}
                >
                  + Adicionar associado
                </button>
              </div>
            </>
          )
        }


      </FieldArray>


    </>
  );
}
