import { useContext, useEffect, useState } from "react";

import { Insumo } from "../../../assets/interfaces/Agricultor";

import { Link } from "react-router-dom";

import { Header } from "../../../components/Header";
import { Loader } from "../../../components/Loader";

import { server } from "../../../services/api";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";

import emptyImage from "../../../assets/images/empty.svg";
import "./styles.scss";
import Swal from "sweetalert2";
import history from "../../../routes/history";
import { Edit3, Trash } from "react-feather";
import Collapsible from "react-collapsible";
import { AgricultorContext } from "../../../contexts/AgricultorContext";

export default function Insumos() {
  const{removerInsumos} = useContext(AgricultorContext)

  const [insumos, setInsumos] = useState<Insumo[]>([]);

  useEffect(() => {
    const getInsumos = async () => {
      const { data } = await trackPromise(server.get("/insumos"), "insumos");
      if (data.resultado.insumosCadastrados) {
        setInsumos(data.resultado.insumosCadastrados);
      }
    };
    getInsumos();
  }, []);

  const insumosPromise = usePromiseTracker({ area: "insumos" });

  const collapsibleInsumoProps = {
    classParentString: "insumo",
    contentContainerTagName: "div",
    triggerTagName: "div",
    className: "table-body",
    openedClassName: "table-body",
    triggerClassName: "table-row",
    triggerOpenedClassName: "table-row",
  };

  const categoria = (id: string) => {
    switch (id) {
      case "1":
        return "Maquinário";
      case "2":
        return "Fertilizantes";
      case "3":
        return "Inseticidas";
      case "4":
        return "Processamentos";
      case "5":
        return "Embalagem";
      case "6":
        return "Mão de obra";
      case "7":
        return "Herbicidas";
      case "8":
        return "Sementes/Mudas/Ramas";
    }
  };

  return (
    <>
      <Header
        titulo="Insumos"
        descricao="Cadastre todos os insumos utilizados para produção"
      />

      <main className="accordion">
        <div className="accordionCard">
          <div className="accordionTitle">
            <h2>Insumos</h2>

            <button onClick={() => history.push("/agricultor/insumo")}>
              Novo insumo
            </button>
          </div>

          <Loader area="insumos" />

          {!!insumos.length && (
            <div className="table insumos">
              <div className="table-head">
                <div className="table-row">
                  <div className="table-headers sm-display">Descrição</div>

                  <div className="table-headers">Categoria</div>

                  <div className="table-headers sm-display">Unidade</div>

                  <div className="table-headers sm-display">Valor Unitário</div>

                  <div className="table-headers" aria-hidden="true"></div>
                </div>
              </div>

              {insumos.map((insumo) => {
                return (
                  <Collapsible
                    {...collapsibleInsumoProps}
                    trigger={
                      <>
                        <div className="table-column sm-display">
                          {insumo.descricao}
                        </div>

                        <div className="table-column">
                          {categoria(insumo.id_categoria)}
                        </div>

                        <div className="table-column sm-display">
                          {insumo.unidade}
                        </div>

                        <div className="table-column sm-display">
                          {new Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          }).format(Number(insumo.custo_unitario))}
                        </div>

                        <div className="table-column">
                          <div className="icon-buttons">
                            <button
                              type="button"
                              className="icon edit"
                              onClick={() =>
                                history.push(`/agricultor/insumo/${insumo.id}`)
                              }
                            >
                              <Edit3 color="#777777" size="1rem" />
                            </button>

                            <button
                              type='button'
                              className='icon remove'
                              onClick={() => {
                                Swal.fire({
                                  title: 'Excluir insumo?',
                                  text: 'Essa ação não pode ser desfeita.',
                                  confirmButtonText: 'Excluir insumo',
                                  cancelButtonText: 'Cancelar',
                                  showConfirmButton: true,
                                  showCancelButton: true,
                                  showDenyButton: false,
                                  showCloseButton: false,
                                  allowOutsideClick: true,
                                  allowEnterKey: false,
                                  allowEscapeKey: true,
                                  backdrop: true,
                                  customClass: {
                                    confirmButton: 'swal-confirm-button',
                                    cancelButton: 'swal-cancel-button',
                                  },
                                  showClass: {
                                    popup:
                                      'animate__animated animate__slideInDown animate__faster',
                                  },
                                  hideClass: {
                                    popup:
                                      'animate__animated animate__fadeOutUp animate__faster',
                                  },
                                }).then((result: any) => {
                                  if (result.isConfirmed) {
                                    removerInsumos(insumo.id || "");

                                      const filteredInsumos = insumos.filter(
                                        (elementoInsumo) => elementoInsumo.id !== insumo.id
                                      );

                                      setInsumos(filteredInsumos);
                                  }
                                });
                              }}
                            >
                              <Trash color='#ff6b6b' size='1rem' />
                            </button>
                          </div>
                        </div>
                      </>
                    }
                  ></Collapsible>
                );
              })}
            </div>
          )}

          {!insumos.length && !insumosPromise.promiseInProgress && (
            <div className="empty">
              <img src={emptyImage} alt="Nenhum insumo encontrado" />

              <h1>Não encontramos nenhum insumo cadastrado.</h1>

              <p>
                Que tal{" "}
                <Link to="/agricultor/insumo">cadastrar um novo insumo</Link>{" "}
                agora?
              </p>
            </div>
          )}
        </div>
      </main>
      <br />
    </>
  );
}
