import { createContext, ReactNode } from "react";

import { Agricultor, AssociadosFormValues, Insumo, Producao } from "../assets/interfaces/Agricultor";

import { server } from "../services/api";

import { toast } from "react-toastify";

import history from "../routes/history";
import { Alimentos } from "../pages/agricultor/producao";

interface AgricultorContextData {
  cadastrarAgricultor(dados: Agricultor): Promise<unknown>;
  buscarAgricultorPorId(id:string): Promise<Agricultor | undefined>;
  alterarAgricultor(dados: Agricultor): Promise<void>;
  cadastrarInsumo(dados: Insumo): Promise<unknown>;
  buscarInsumoPorId(id: string): Promise<Insumo | undefined>;
  alterarInsumo(dados: Insumo): Promise<void>;
  removerAlimentoProduzidos(id: string): Promise<void>;
  removerInsumos(id: string): Promise<void>;
  cadastrarProducao(dados: Producao): Promise<unknown>;
  buscarProducaoPorId(id: string): Promise<Producao | undefined>;
  alterarProducao(dados: Producao): Promise<void>;
  getAlimentos(): Promise<Alimentos>;
}

interface AgricultorProviderProps {
  children: ReactNode;
}

export type editarAgricultor = {
  id?: string;
  tipo: number;
  nome_proponente: string;
  email: string;
  cpf?: string;
  cnpj?: string;
  telefone?: string;
  celular: string;
  dap?: string;
  cep: string;
  logradouro?: string;
  complemento?: string;
  bairro?: string;
  cidade: string;
  estado: string;
  banco: string;
  area_produzida: number;
  conta_bancaria: string;
  agencia: string;
  operacao?: string;
  senha: string;
  numero_associados?: number;
  numero_associados_lei?: number;
  numero_associados_dap?: number;
  representante_legal?: string;
  representante_cpf?: string;
  ic_priorizado?: boolean;
  associados_cpf?: string[];
  associados: AssociadosFormValues[];
}

export const AgricultorContext = createContext({} as AgricultorContextData);

export function AgricultorProvider({ children }: AgricultorProviderProps) {
  async function cadastrarAgricultor(dados: Agricultor) {
    dados.tipo = Number(dados.tipo);
    
    switch (dados.tipo) {
      case 1:
        try {
          const pf = await server.post("/agricultores", {
            tipo: dados.tipo,
            nome_proponente: dados.nome_proponente,
            email: dados.email,
            cpf: dados.cpf,
            telefone: dados.telefone,
            celular: dados.celular,
            dap: dados.dap,
            cep: dados.cep,
            logradouro: dados.logradouro,
            complemento: dados.complemento,
            bairro: dados.bairro,
            cidade: dados.cidade,
            estado: dados.estado,
            area_produzida: dados.area_produzida,
            banco: dados.banco,
            conta_bancaria: dados.conta_bancaria,
            agencia: dados.agencia,
            operacao: dados.operacao,
            senha: dados.senha,
            ic_priorizado: dados.ic_priorizado,
          });

          if (pf.data) {
            toast("Sua conta foi criada com sucesso");
            history.push("/agricultor");
          }
        } catch (error: any) {
          const erro = error.response.data.error;
          toast(erro);
        }
        break;

      case 2:
        try {
          const pj = await server.post("/agricultores", {
            tipo: dados.tipo,
            nome_proponente: dados.nome_proponente,
            email: dados.email,
            cnpj: dados.cnpj,
            telefone: dados.telefone,
            celular: dados.celular,
            dap: dados.dap,
            cep: dados.cep,
            logradouro: dados.logradouro,
            bairro: dados.bairro,
            complemento: dados.complemento,
            cidade: dados.cidade,
            estado: dados.estado,
            area_produzida: dados.area_produzida,
            banco: dados.banco,
            conta_bancaria: dados.conta_bancaria,
            agencia: dados.agencia,
            operacao: dados.operacao,
            senha: dados.senha,
            numero_associados: dados.numero_associados,
            numero_associados_lei: dados.numero_associados_lei,
            numero_associados_dap: dados.numero_associados_dap,
            representante_legal: dados.representante_legal,
            representante_cpf: dados.representante_cpf,
            ic_priorizado: dados.ic_priorizado,
            associados: dados.associados
          });

          if (pj.data) {
            toast("Sua conta foi criada com sucesso");
            history.push("/agricultor");
          }
        } catch (error: any) {
          const erro = error.response.data.error;
          toast(erro);
        }
        break;
        
    }
  }

  async function buscarAgricultorPorId(id: string){
    try {
      const {data} = await server.get(`/agricultores/${id}`);
      const agri = data;

        const agricultor:Agricultor = {
          nome_proponente: agri?.nome_proponente,
          cpf: agri?.cpf,
          tipo: agri?.tipo,
          email: agri?.email,
          cnpj: agri?.cnpj,
          telefone: agri?.telefone,
          celular: agri?.celular,
          dap: agri?.dap,
          cep: agri?.endereco.cep,
          logradouro: agri?.endereco?.logradouro,
          bairro: agri?.endereco?.bairro,
          complemento: agri?.endereco?.complemento,
          cidade: agri?.endereco?.cidade,
          estado: agri?.endereco?.estado,
          area_produzida: agri?.area_produzida,
          banco: agri?.banco,
          conta_bancaria: agri?.conta_bancaria,
          agencia: agri?.agencia,
          operacao: agri?.operacao,
          senha: agri?.senha,
          numero_associados: agri?.numero_associados,
          numero_associados_lei: agri?.numero_associados_lei,
          numero_associados_dap: agri?.numero_associados_dap,
          representante_legal: agri?.representante_legal,
          representante_cpf: agri?.representante_cpf,
          ic_priorizado: agri?.ic_priorizado,
          associados_cpf: agri?.associados_cpf,
          associados: agri?.associados
        };
        return agricultor;
    } catch (error: any) {
      console.log(error);
    }
  }

  async function alterarAgricultor(dados: Agricultor) {
    console.log('tipo dados: ', dados.tipo)
    switch (dados.tipo) {
      case 1:
        try {
          const pf = await server.put("/agricultores", {
            id: dados.id,
            tipo: dados.tipo,
            nome_proponente: dados.nome_proponente,
            email: dados.email,
            cpf: dados.cpf,
            telefone: dados.telefone,
            celular: dados.celular,
            dap: dados.dap,
            cep: dados.cep,
            logradouro: dados.logradouro,
            complemento: dados.complemento,
            bairro: dados.bairro,
            cidade: dados.cidade,
            estado: dados.estado,
            area_produzida: dados.area_produzida,
            banco: dados.banco,
            conta_bancaria: dados.conta_bancaria,
            agencia: dados.agencia,
            operacao: dados.operacao,
            senha: dados.senha,
            ic_priorizado: dados.ic_priorizado,
          });

          if (pf.data) {
            toast("Sua conta foi criada com sucesso");
            history.push("/agricultor");
          }
        } catch (error: any) {
          const erro = error.response.data.error;
          toast(erro);
        }
        break;

      case 2:
        try {
          const pj = await server.put("/agricultores", {
            id:dados.id,
            tipo: dados.tipo,
            nome_proponente: dados.nome_proponente,
            email: dados.email,
            cnpj: dados.cnpj,
            telefone: dados.telefone,
            celular: dados.celular,
            dap: dados.dap,
            cep: dados.cep,
            logradouro: dados.logradouro,
            bairro: dados.bairro,
            complemento: dados.complemento,
            cidade: dados.cidade,
            estado: dados.estado,
            area_produzida: dados.area_produzida,
            banco: dados.banco,
            conta_bancaria: dados.conta_bancaria,
            agencia: dados.agencia,
            operacao: dados.operacao,
            senha: dados.senha,
            numero_associados: dados.numero_associados,
            numero_associados_lei: dados.numero_associados_lei,
            numero_associados_dap: dados.numero_associados_dap,
            representante_legal: dados.representante_legal,
            representante_cpf: dados.representante_cpf,
            ic_priorizado: dados.ic_priorizado,
            associados_cpf: dados.associados_cpf,
            associados: dados.associados
          });

          if (pj.data) {
            toast("Sua conta foi criada com sucesso");
            history.push("/agricultor");
          }
        } catch (error: any) {
          const erro = error.response.data.error;
          toast(erro);
        }
        break;
    }
  }

  async function cadastrarInsumo(dados: Insumo) {
    try {
      const { data } = await server.post("/insumos", {
        id_categoria: dados.id_categoria,
        descricao: dados.descricao,
        unidade: dados.unidade,
        custo_unitario: dados.custo_unitario,
      });

      if (data) {
        toast("Insumo criado com sucesso");
        history.push("/agricultor/insumos");
      }
    } catch (error: any) {
      const erro = error.response.data.error;
      toast(erro);
    }
  }

  async function buscarInsumoPorId(id: string) {
    try {
      const { data } = await server.get(`/insumos/${id}`);

      const insumo: Insumo = {
        id: data.resultado.id,
        descricao: data.resultado.descricao,
        custo_unitario: data.resultado.custo_unitario,
        unidade: data.resultado.unidade,
        id_categoria: data.resultado.id_categoria,
        id_agricultor: data.resultado.id_agricultor,
      };

      return insumo;
    } catch (error: any) {
      console.log(error);
    }
  }

  async function alterarInsumo(dados: Insumo) {
    try {
      const { data } = await server.put("/insumos", {
        id: dados.id,
        descricao: dados.descricao,
        custo_unitario: dados.custo_unitario,
        unidade: dados.unidade,
        id_categoria: dados.id_categoria,
      });

      if (data) {
        toast("Insumo alterado com sucesso!");
        history.push("/agricultor/insumos");
      }
    } catch (error: any) {
      const erro = error.response.data.error;
      toast(erro);
    }
  }

  async function removerInsumos(id: string) {
    try {
      const response = await server.delete(`/agricultor/insumos/${id}`);
      if (response.status === 200) {
        toast("Insumo removido com sucesso!");
      }
    } catch (error: any) {
      // erro = error.response.data.error;
      console.log(error);
    }
  }


  async function cadastrarProducao(dados: Producao) {
    try {
      const { data } = await server.post("/producoes", {
        id_alimento: dados.id,
        quantidade_produzida: dados.quantidade_produzida,
        custo_total: dados.custo_total,
        //insumosDaProducao: dados.insumosDaProducao,
        area_produzida: dados.area_produzida,
        nCycle: dados.nCycle,
        isPlanted: dados.isPlanted,
        ic_organico: dados.ic_organico
      });

      if (data) {
        toast("Produção criada com sucesso");
        history.push("/agricultor/producoes");
      }
    } catch (error: any) {
      const erro = error.response.data.error;
      toast(erro);
    }
  }

  async function buscarProducaoPorId(id: string) {
    try {
      const { data } = await server.get(`/producoes/${id}`);

      const producao: Producao = {
        id: data.resultado.id,
        quantidade_produzida: data.resultado.quantidade_produzida,
        area_produzida: data.resultado.area_produzida,
        custo_total: data.resultado.custo_total,
       // listaInsumos: data.resultado.listaInsumos,
        nCycle: data.resultado.nCycle,
        isPlanted: data.resultado.isPlanted
      };

      return producao;
    } catch (error: any) {
      console.log(error);
    }
  }

  async function alterarProducao(dados: Producao) {
    try {
      const { data } = await server.put("/producoes", {
        id: dados.id,
        quantidade_produzida: dados.quantidade_produzida,
        custo_total: dados.custo_total,
        //insumosDaProducao: dados.insumosDaProducao,
        area_produzida: dados.area_produzida,
        nCycle: dados.nCycle,
        isPlanted: dados.isPlanted
      });

      if (data) {
        toast("Produção alterada com sucesso!");
        history.push("/agricultor/producoes");
      }
    } catch (error: any) {
      const erro = error.response.data.error;
      toast(erro);
    }
  }

  async function removerAlimentoProduzidos(id: string) {
    try {
      const response = await server.delete(`/agricultor/producoes/${id}`);
      if (response.status === 200) {
        toast("Produção removido com sucesso!");
      }
    } catch (error: any) {
      console.log(error);
    }
  }

  async function getAlimentos() {
    try {
      const { data } = await server.get("/alimentos");
      return data;
    } catch (error: any) {
      const erro = error.response.data.error;
      toast(erro);
    }
  }

  return (
    <AgricultorContext.Provider
      value={{
        cadastrarAgricultor,
        buscarAgricultorPorId,
        alterarAgricultor,
        cadastrarInsumo,
        buscarInsumoPorId,
        alterarInsumo,
        removerAlimentoProduzidos,
        removerInsumos,
        cadastrarProducao,
        buscarProducaoPorId,
        alterarProducao,
        getAlimentos
      }}
    >
      {children}
    </AgricultorContext.Provider>
  );
}
