import { useContext, useEffect, useState } from "react";
import { ChevronDown } from "react-feather";
import Collapsible from "react-collapsible";
import moment from "moment";
import Swal from "sweetalert2";

import { Edital } from "../../../assets/interfaces/Edital";

import { Header } from '../../../components/Header';
import { Loader } from '../../../components/Loader';
import { Status } from "../../../components/Status";

import { server } from "../../../services/api";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";

import { Alimento, EscolaContext } from "../../../contexts/EscolaContext";

import emptyImage from "../../../assets/images/empty.svg";
import { AuthContext } from "../../../contexts/AuthContext";
import "./styles.scss";


export default function SelecionarProposta(){

  const [editais, setEditais] = useState<Edital[]>([]);
  const [editaisSelecionados, setEditaisSelecionados] = useState<Edital[]>([]);

  const { alterarStatusEdital, removerEdital } = useContext(EscolaContext);
  const { usuario } = useContext(AuthContext);

  const collapsibleEditalProps = {
    classParentString: "edital",
    contentContainerTagName: "div",
    triggerTagName: "div",
    className: "table-body",
    openedClassName: "table-body",
    triggerClassName: "table-row",
    triggerOpenedClassName: "table-row",
  };

  useEffect(() => {
    getEditais();
    setEditaisSelecionados([]);
  }, []);

  // FUNCOES AUXILIARES

  const editaisPromise = usePromiseTracker({ area: "editais" });

  const getEditais = async () => {
    const { data } = await trackPromise(
      server.get("/editais", { params: { entidadeId: usuario!.id, a:2 } }),
      "editais"
    );

    if (data.results) {
      let propostas = formataPropostas(data.results)
      setEditais(propostas);
    }
  };

  const formataPropostas = (editais: any[]) => {
    interface AlimentosFormatados {
      [alimentoId : string ] : any
    }
    let alimentos : AlimentosFormatados = {}

    for(let i = 0; i < editais.length; i++) {
      delete editais[i].candidatos

      let alimentos_candidatados = editais[i].alimentos_candidatados
      alimentos = {}

      for (let j = 0; j < alimentos_candidatados.length; j++) {
        // separa as candidaturas por alimento
        let candidatura_atual = alimentos_candidatados[j]
        let idAlimento = candidatura_atual.alimentoId

        if(!alimentos[idAlimento]) {
          alimentos[idAlimento] = {
            ...candidatura_atual.alimento,
            agricultores: []
          }
        }

        alimentos[idAlimento].agricultores.push(candidatura_atual.agricultor)
      }
      
      let idsAlimentos = Object.keys(alimentos)

      editais[i].alimentos = editais[i].alimentos.map((alimento: any) => {
        // insere as respectivas candidaturas aos alimentos
        let id = alimento.alimentosId
        let candidaturas = []

        if(alimentos[id]) {
          candidaturas.push(...alimentos[id].agricultores)
        }

        return {
          ...alimento,
          candidaturas
        }
      })
      editais[i].candidaturas = idsAlimentos.map(idAlimento => alimentos[idAlimento])
    }

    return editais
  }

  const rowCandidatoAlimentoTable = (edital: any, alimento: any, candidato : any, pos: number) => {
 
    let regiao : { [key: string]: string } = {
      "1": "Local",
      "2": "Territorio",
      "3": "Estado",
      "4": "Pais",
    }

    let tipoCadastro = {
      1: "",
      2: "",
      3: ""
    }

    let endereco_edital = edital.entidade.endereco
    let endereco_agricultor = candidato.endereco

    let regiao_classificacao: string = "4";

    if(endereco_edital.bairro == endereco_agricultor.bairro && endereco_edital.cidade == endereco_agricultor.cidade) {
      regiao_classificacao = "1";
    }

    if(endereco_edital.bairro != endereco_agricultor.bairro && endereco_edital.cidade == endereco_agricultor.cidade) {
      regiao_classificacao = "2";
    }


    /*
      Organico -> no cadastro da producao
      tipo de proposta -> formal ou nao
    */

    return (
      <tr>
        <td>{`${pos} º`}</td> {/* Posicao */}
        <td>{`${candidato.nome_proponente}`}</td> {/* Nome do candidato */}
        <td>{`${regiao[regiao_classificacao]}`}</td> {/* Regiao */}
        <td>{candidato.ic_priorizado ? "Sim" : "Não"}</td> {/* Se eh priorizado */}
        <td>Sim</td> {/* Se eh organico */}
        <td>Formal</td> {/* Tipo de proposta */}
        <td>{alimento.quantidade}</td> {/* Quant. Requerida */}
        <td>{alimento.quantidade}</td> {/* Quant. Ofertada */}
        <td>{alimento.quantidade}</td> {/* Quant. Contratada */}
      </tr>
    )

  }

  const alertaAtivarEdital = (edital : Edital) => {
    Swal.fire({
      title: "Ativar edital?",
      text: "Você pode suspendê-lo novamente quando desejar.",
      confirmButtonText: "Ativar edital",
      cancelButtonText: "Cancelar",
      showConfirmButton: true,
      showCancelButton: true,
      showDenyButton: false,
      showCloseButton: false,
      allowOutsideClick: true,
      allowEnterKey: false,
      allowEscapeKey: true,
      backdrop: true,
      customClass: {
        cancelButton: "swal-cancel-button",
      },
      showClass: {
        popup:
          "animate__animated animate__slideInDown animate__faster",
      },
      hideClass: {
        popup:
          "animate__animated animate__fadeOutUp animate__faster",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        alterarStatusEdital(edital, 3);
      }
    });
  }

  const verificaCriterio1 = (entidade: any, agricultor: any) => {
    /*
      local: Mesmo municipio que a escola
      territorio : ???
      estado : Mesmo estado que a escola
      pais : Mesmo pais que a escola
    */ 

    function compare(key: string, obj1: any, obj2: any) {
      if(obj1[key] == obj2[key]) return true;
      return false
    }

    let end_agricultor = agricultor.endereco;
    let end_entidade   = entidade.endereco;

    if(compare("cidade", end_agricultor, end_entidade) && compare("estado", end_agricultor, end_entidade)) {
      return "local"
    } else if(compare("estado", end_agricultor, end_entidade)) {
      return "estado"
    } 
    
    
    return "pais"
    
  }

  const verificaCriterio2 = (entidade: any, agricultor: any) => {
    /*
      priorizado : a info vem no cadastro do agricultor
      n_priorizado : a info vem no cadastro do agricultor
    */

    if(agricultor.ic_priorizado) {
      return "priorizado"
    }

    return "n_priorizado"
  }

  const verificaCriterio3 = (entidade: any, agricultor: any) => {
    /*
      organico: Vem no cadastro da produção (ERA PRA VIR MAS A BIA NAO IMPLEMENTOU)
      n_organico: Vem no cadastro da produção (ERA PRA VIR MAS A BIA NAO IMPLEMENTOU)
    */

    return "organico"
  }

  const verificaCriterio4 = (entidade: any, agricultor: any) => {
    /*
      Vem no cadastro do agricultor (tipo 1, tipo 2, tipo 3)
      formal:     tipo 2
      informal:  tipo 3
      individual: tipo 1
    */

    let tiposDict : {[value: number]: string} = {
      1: "individual",
      2: "formal",
      3: "informal",
    }
    let idTipo: number = Number(agricultor.tipo)

    if(tiposDict[idTipo]) {
      return tiposDict[idTipo]
    } else {
      return 0;
    }
    
  }

  const calculaPesoCandidato = (entidade: any, candidato: any) => {

    /*
        apos cada proposta ter o seu peso, realizar a ordenação baseada no peso
      das propostas
    */

      let peso = 0

      interface pesos {
        [peso_n: string] : {
          [label: string]: any;
        }
      }

      let pesos_calc : pesos = {
        "pesos_n1":  {
          local: 4000,
          territorio: 3000,
          estado: 2000,
          pais: 1000,
          fn: verificaCriterio1
        },
  
        "pesos_n2": {
          priorizado: 200,
          n_priorizado: 100,
          fn: verificaCriterio2
        },
  
        "pesos_n3": {
          organico: 20,
          n_organico: 10,
          fn: verificaCriterio3
        },
  
        "pesos_n4": {
          formal: 3,
          informal: 2,
          individual: 1,
          fn: verificaCriterio4
        }
      }

      let keys = Object.keys(pesos_calc)

      for (let key of keys) {
        let obj = pesos_calc[`${key}`];
        let criterio = obj.fn(entidade, candidato)
        peso += obj[criterio]
      }

      return peso
  }

  const tabelaCandidatosAlimentos = (edital: any, alimento: any) => {
    let qtd_candidaturas : number = alimento.candidaturas ? alimento.candidaturas.length : 0;
    let flag = 1

    if(flag) {
      for(let i = 0; i < qtd_candidaturas; i++) {
        // calcula o peso de cada candidatura
        let peso = calculaPesoCandidato(edital.entidade, alimento.candidaturas[i])
        alimento.candidaturas[i].peso = peso;
      }

      // Função de comparação para ordenar por peso
      const compararPorPeso = (a: any, b: any) => {
        // return a.peso - b.peso;
        return b.peso - a.peso; // ordena do maior para o menor

      };

      // Ordenar o array de objetos por peso
      alimento.candidaturas.sort(compararPorPeso);
    }

    return alimento.candidaturas?.map((candidato: any, index: number) => {
      return rowCandidatoAlimentoTable(edital, alimento, candidato, index + 1)
    })
  }

  return(
    <>
      <Header
        titulo="Selecionar Proposta"
        descricao="Uma visão geral de todas as propostas feitas à escola pelos agricultores"
      />

      <main id='selecionar-proposta'>
        <div className='selecionar-proposta-card'>
          <Loader area = 'editais' />

          {editais.length > 0 && editais && (
            <div className="table editais-aplicados">
              {/* <FilterBar setFilterFields={setFilterValues}></FilterBar> */}

              <div className="table-head">

                <div className="table-row">
                  <div className="table-headers">Processo</div>

                  <div className="table-headers sm-display">
                    Chamada Pública
                  </div>

                  <div className="table-headers sm-display">Nome do Edital</div>

                  <div className="table-headers sm-display">Data Inicial</div>

                  <div className="table-headers sm-display">Data Final</div>

                  <div className="table-headers">Status</div>

                  <div className="table-headers" aria-hidden="true"></div>
                </div>
              </div>

              {/* LISTA OS EDITAIS, DENTRO DE CADA EDITAL LISTA OS ALIMENTOS DO EDITAL, DENTRO DE CADA ALIMENTO LISTA AS PROPOSTAS */}
              {editais && editais.map((edital) => {
                return (
                  <div className="edital-container">
                    <Collapsible
                      {...collapsibleEditalProps}
                      key={edital.id}
                      trigger={
                        <>
                          <div className="table-column">
                            {edital.numero_processo}
                          </div>

                          <div className="table-column sm-display">
                            {edital.chamada_publica}
                          </div>

                          <div className="table-column sm-display">
                            {edital.nome}
                          </div>

                          <div className="table-column sm-display">
                            {moment(edital.data_inicio).format("DD/MM/YYYY")}
                          </div>

                          <div className="table-column sm-display">
                            {moment(edital.data_final).format("DD/MM/YYYY")}
                          </div>

                          <div className="table-column">
                            <Status status={edital.status || 0} />
                          </div>

                          <div className="table-column">
                            <button type="button">
                              <ChevronDown color="#333333" size="1rem" />
                            </button>
                          </div>
                        </>
                      }
                    >
                      <section className="edital-card">
                        <div className="edital-card-header">
                          {/* Cabecalho do edital */}
                          <div>

                            <h2>
                              {edital.nome}{" "}
                              <Status status={edital.status || 0} />
                            </h2>

                            <div>

                              {edital.status === 2 && (
                                <button
                                  type="button"
                                  className="ativar-edital"
                                  onClick={() => alertaAtivarEdital(edital) }
                                >
                                  Ativar edital
                                </button>
                              )}
                            </div>

                          </div>

                          <h3>Chamada Pública Nº {edital.chamada_publica}</h3>

                          <h4>Processo nº {edital.numero_processo}</h4>
                        </div>

                        {editais.length > 0 && editais && (
                          <div className="table editais-aplicados">
                            {/* <FilterBar setFilterFields={setFilterValues}></FilterBar> */}

                            <div className="table-head">

                                <div className="table-row">
                                <div className="table-headers">Produto</div>

                                <div className="table-headers sm-display">Quantidade</div>

                                <div className="table-headers sm-display">Unidade</div>

                                <div className="table-headers sm-display">Valor Unitário</div>

                                <div className="table-headers sm-display">Valor total</div>

                                <div className="table-headers" aria-hidden="true"></div>
                                </div>
                            </div>

                            {/* Lista alimentos requisitados no edital */}
                            {edital.alimentos.map((alimento) => {
                              return (
                                <div className="edital-container">
                                  <Collapsible
                                      {...collapsibleEditalProps}
                                      key={alimento.nome}
                                      trigger={
                                        <>
                                          <div className="table-column"> {alimento.nome} </div>

                                          <div className="table-column sm-display"> {alimento.quantidade} </div>

                                          <div className="table-column sm-display"> {alimento.unidade} </div>

                                          <div className="table-column sm-display">
                                            {new Intl.NumberFormat("pt-BR", {
                                              style: "currency",
                                              currency: "BRL",
                                            }).format(Number(alimento.valor))}
                                          </div>

                                          <div className="table-column sm-display">
                                            {new Intl.NumberFormat("pt-BR", {
                                              style: "currency",
                                              currency: "BRL",
                                            }).format(
                                              Number(
                                                alimento.valor *
                                                alimento.quantidade
                                              )
                                            )}
                                          </div>

                                          <div className="table-column-produtos">
                                            <button type="button">
                                                <ChevronDown color="#333333" size="1rem" />
                                            </button>
                                          </div>
                                        </>
                                      }
                                  > {/* Fecha abertura do Collapsible */}

                                    <section className="edital-card-products">
                                      <div className="edital-card-header">

                                        <div className="edital-card-body">

                                          <div className="table-column-propostas">
                                            <h6>Quantitativo de gêneros alimentícios</h6>

                                            {edital.alimentos.length > 0 ? (
                                              <div>
                                                <table className="table-use">
                                                  <thead>
                                                    <tr>
                                                      <th scope="col">Classificação</th>
                                                      <th scope="col">Proponente</th>
                                                      <th scope="col">Região</th>
                                                      <th scope="col">Grupo priorizado</th>
                                                      <th scope="col">Orgânico-Agroecológico</th>
                                                      <th scope="col">Tipo de Proposta</th>
                                                      <th scope="col">Quantidade requerida</th>
                                                      <th scope="col">Quantidade Ofertada</th>
                                                      <th scope="col">Quantidade contratada</th>
                                                      <th scope="col"></th>
                                                    </tr>
                                                    {
                                                      // RENDERIZA A TABELA DE CANDIDATOS
                                                      tabelaCandidatosAlimentos(edital, alimento)
                                                      
                                                    }
                                                  </thead>
                                                </table>

                                                <div className="button-produto">
                                                  <button type="button" onClick={() => { }}> Confirmar Seleção </button>
                                                </div>

                                              </div>
                                            ) : (
                                              <p>
                                                Não há gêneros alimentícios cadastrados neste
                                                edital.
                                              </p>
                                            )}
                                          </div>
                                            
                                        </div>

                                      </div>
                                    </section>
                                  </Collapsible>
                                </div>
                              );
                            })}
                          </div>
                          )}

                      </section>
                    </Collapsible>
                  </div>
                ); // fim do return do map editais
              })}
            </div>
          )}

          {!editais.length && !editaisPromise.promiseInProgress && (
            <div className="nenhum-edital">
              <img src={emptyImage} alt="Nenhum edital encontrado" />
              <h1>Não encontramos nenhuma proposta cadastrada.</h1>
            </div>
          )}
        </div>
      </main>
    </>
  );
}
