import { useContext, useEffect, useState } from "react";
import {
  Calendar,
  ChevronDown,
  DollarSign,
  Edit3,
  Trash,
  Link as LinkIcon,
} from "react-feather";
import Collapsible from "react-collapsible";
import moment from "moment";
import Swal from "sweetalert2";

import { Edital } from "../../../../assets/interfaces/Edital";

import { Header } from "../../../../components/Header";
import { Loader } from "../../../../components/Loader";
import { Status } from "../../../../components/Status";
import FilterBar, {
  FiltrosEditaisAplicadosFormValues,
} from "../../../../components/FilterBar";

import { server } from "../../../../services/api";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import history from "../../../../routes/history";

import { EscolaContext } from "../../../../contexts/EscolaContext";

import emptyImage from "../../../../assets/images/empty.svg";
import "./styles.scss";
import { useHistory } from "react-router-dom";

export default function EditaisAplicados() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filterValues, setFilterValues] = useState(
    {} as FiltrosEditaisAplicadosFormValues
  );
  const [editais, setEditais] = useState<Edital[]>([]);
  const [editaisSelecionados, setEditaisSelecionados] = useState<Edital[]>([]);

  const { aprovarEdital, removerEdital, alterarStatusEdital } = useContext(EscolaContext);

  const collapsibleEditalProps = {
    classParentString: "edital",
    contentContainerTagName: "div",
    triggerTagName: "div",
    className: "table-body",
    openedClassName: "table-body",
    triggerClassName: "table-row",
    triggerOpenedClassName: "table-row",
  };

  const history = useHistory();

  useEffect(() => {
    const getEditais = async () => {
      var editais:any = await trackPromise(server.get("/agricultores/editais", {params: {status:"aplicado"}}), "editais");
      console.log(editais);
      editais = editais.data.results.map((e:any) => e.edital);
      editais = editais.map((edital: Edital) => {
        return {
          ...edital,
          status: 3
        }
      });
      setEditais(editais);
    };

    getEditais();
    setEditaisSelecionados([]);
  }, []);

  const editaisPromise = usePromiseTracker({ area: "editais" });

  function handleChangeCheckbox(edital: Edital) {
    if (editaisSelecionados.includes(edital)) {
      const newEditais = editaisSelecionados.filter((item) => item !== edital);
      setEditaisSelecionados(newEditais);
    }
    else {
      const newEditais = editaisSelecionados.concat(edital);
      setEditaisSelecionados(newEditais);
    }
  }

  async function handleApprove() {
    //console.log(editaisSelecionados);
    try {
      await editaisSelecionados.forEach((edital) => {
        aprovarEdital(edital.id||"");
      });
      history.push("/agricultor/editais/aprovados");
    } catch (err) {
      console.log(err);
    }
  }

  async function handleDelete() {
    //console.log(editaisSelecionados);
    try {
      await editaisSelecionados.forEach((edital) => {
      /*  removerCandidatoEdital((edital.id) as string);*/
      });
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      <Header
        titulo="Editais aplicados"
        descricao="Visão geral de todos os editais que você se candidatou."
      />

      <main id="editais-aplicados">
        <div className="editais-aplicados-card">
          <Loader area="editais" />

          {editais.length > 0 && editais && (
            <div className="table editais-aplicados">
              {/* <FilterBar setFilterFields={setFilterValues}></FilterBar> */}

              <div className="table-head">

                <div className="table-row">
                  <div className="table-headers">Processo</div>

                  <div className="table-headers sm-display">
                    Chamada Pública
                  </div>

                  <div className="table-headers sm-display">Nome do Edital</div>

                  <div className="table-headers sm-display">Data Inicial</div>

                  <div className="table-headers sm-display">Data Final</div>

                  <div className="table-headers">Status</div>

                  <div className="table-headers" aria-hidden="true"></div>
                </div>
              </div>

              {editais &&
                editais.map((edital) => {
                  return (
                    <div className="edital-container">
                      <span className="checkbox-container">
                        <input className="checkbox-input" type="checkbox" onChange={() => handleChangeCheckbox(edital)} />
                      </span>
                      <Collapsible
                        {...collapsibleEditalProps}
                        key={edital.id}
                        trigger={
                          <>
                            <div className="table-column">
                              {edital.numero_processo}
                            </div>

                            <div className="table-column sm-display">
                              {edital.chamada_publica}
                            </div>

                            <div className="table-column sm-display">
                              {edital.nome}
                            </div>

                            <div className="table-column sm-display">
                              {moment(edital.data_inicio).format("DD/MM/YYYY")}
                            </div>

                            <div className="table-column sm-display">
                              {moment(edital.data_final).format("DD/MM/YYYY")}
                            </div>

                            <div className="table-column">
                              <Status status={edital.status || 0} />
                            </div>

                            <div className="table-column">
                              <button type="button">
                                <ChevronDown color="#333333" size="1rem" />
                              </button>
                            </div>
                          </>
                        }
                      >
                        <section className="edital-card">
                          <div className="edital-card-header">
                            <div>
                              <h2>
                                {edital.nome}{" "}
                                <Status status={edital.status || 0} />
                              </h2>
                            </div>

                            <h3>Chamada Pública Nº {edital.chamada_publica}</h3>

                            <h4>Processo nº {edital.numero_processo}</h4>
                          </div>

                          <div className="edital-card-body">
                            <div>
                              <h6>Data inicial</h6>

                              <p>
                                <Calendar color="#333333" size="1rem" />
                                {moment(edital.data_inicio).format("DD/MM/YYYY")}
                              </p>
                            </div>

                            <div>
                              <h6>Data de inicio da inscrição</h6>

                              <p>
                                <Calendar color="#333333" size="1rem" />
                                {moment(edital.data_inicio_inscricao).format("DD/MM/YYYY")}
                              </p>
                            </div>

                            <div>
                              <h6>Data final para inscrição</h6>

                              <p>
                                <Calendar color="#333333" size="1rem" />
                                {moment(edital.data_fim_inscricao).format("DD/MM/YYYY")}
                              </p>
                            </div>

                            <div>
                              <h6>Data de seleção dos projetos de venda</h6>

                              <p>
                                <Calendar color="#333333" size="1rem" />
                                {moment(edital.data_selecao).format("DD/MM/YYYY")}
                              </p>
                            </div>

                            <div>
                              <h6>Data final</h6>

                              <p>
                                <Calendar color="#333333" size="1rem" />
                                {moment(edital.data_final).format("DD/MM/YYYY")}
                              </p>
                            </div>

                            {!!edital.url_arquivo && (
                              <div>
                                <h6>URL do edital</h6>

                                <p>
                                  <LinkIcon color="#333333" size="1rem" />{" "}
                                  <a href={edital.url_arquivo.startsWith('http') ? edital.url_arquivo : "https://"+edital.url_arquivo} target="_blank">
                                        {edital.url_arquivo}
                                      </a>
                                </p>
                              </div>
                            )}

                            <div>
                              <h6>
                                Total de todos os alimentos a serem adquiridos
                              </h6>

                              <p>
                                <DollarSign color="#333333" size="1rem" />
                                {new Intl.NumberFormat("pt-BR", {
                                  style: "currency",
                                  currency: "BRL",
                                }).format(
                                  Number(
                                    edital.alimentos.reduce(function (a, b) {
                                      return a + b.valor;
                                    }, 0)
                                  )
                                )}
                              </p>
                            </div>

                            <div>
                              <h6>Quantitativo de gêneros alimentícios</h6>

                              {edital.alimentos.length > 0 ? (
                                <table>
                                  <thead>
                                    <tr>
                                      <th scope="col">Produto</th>
                                      <th scope="col">Unidade</th>
                                      <th scope="col">Quantidade</th>
                                      <th scope="col">Valor unitário</th>
                                      <th scope="col">Valor total</th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {edital.alimentos.map((alimento) => {
                                      return (
                                        <tr key={alimento.nome}>
                                          <td data-label="Produto">
                                            {alimento.nome}
                                          </td>
                                          <td data-label="Unidade">
                                            {alimento.unidade}
                                          </td>
                                          <td data-label="Quantidade">
                                            {alimento.quantidade}
                                          </td>
                                          <td data-label="Valor unitário">
                                            {new Intl.NumberFormat("pt-BR", {
                                              style: "currency",
                                              currency: "BRL",
                                            }).format(Number(alimento.valor))}
                                          </td>
                                          <td data-label="Valor total">
                                            {new Intl.NumberFormat("pt-BR", {
                                              style: "currency",
                                              currency: "BRL",
                                            }).format(
                                              Number(
                                                alimento.valor *
                                                alimento.quantidade
                                              )
                                            )}
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              ) : (
                                <p>
                                  Não há gêneros alimentícios cadastrados neste
                                  edital.
                                </p>
                              )}
                            </div>
                          </div>
                        </section>
                      </Collapsible>
                    </div>
                  );
                })}
            </div>
          )}

          {!editais.length && !editaisPromise.promiseInProgress && (
            <div className="nenhum-edital">
              <img src={emptyImage} alt="Nenhum edital encontrado" />

              <h1>Não encontramos nenhum edital cadastrado.</h1>
            </div>
          )}  
          
        </div>
      </main>
      <br />
    </>
  );
}
