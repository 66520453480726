import React from "react";

interface InputWithMaskProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  onlyCNPJ?: boolean;
}

const InputWithMask: React.FC<InputWithMaskProps> = ({
  children,
  onChange,
  onlyCNPJ = false,
  ...rest
}) => {
  function maskIt(value: string) {
    value = value.replace(/\D/g, "");

    if (value.length <= 11 && !onlyCNPJ) {
      value = value.replace(/(\d{3})(\d)/, "$1.$2");
      value = value.replace(/(\d{3})(\d)/, "$1.$2");
      value = value.replace(/(\d{3})(\d{1})/, "$1-$2");
    } else {
      value = value.substring(0, 14);
      value = value.replace(/(\d{2})(\d)/, "$1.$2");
      value = value.replace(/(\d{3})(\d)/, "$1.$2");
      value = value.replace(/(\d{3})(\d)/, "$1/$2");
      value = value.replace(/(\d{4})(\d)/, "$1-$2");
    }

    return value;
  }

  return (
    <input
      onChange={(event) => {
        let editedEvent: React.ChangeEvent<HTMLInputElement> = {
          ...event,
          target: { ...event.target, value: maskIt(event.target.value) },
        };

        onChange && onChange(editedEvent);
      }}
      {...rest}
    >
      {children}
    </input>
  );
};

export default InputWithMask;
