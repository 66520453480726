import React from "react";
import styles from "./BaseButton.module.scss";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

const BaseButton: React.FC<ButtonProps> = ({ children, ...rest }) => {
  return (
    <button className={styles.baseButton} {...rest}>
      {children}
    </button>
  );
};

export default BaseButton;
