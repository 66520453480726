import React from "react";
import { Dropdown } from "primereact/dropdown";

import { Error } from "../Error";

import styles from "../../common/Cadastro.module.scss";
import { FormikErrors, FormikTouched } from "formik";
import {
  EditalCadastroFormValues,
  ProdutoFormValues,
} from "../../assets/interfaces/Edital";
import "./custom_dropdown.css";

type Props<T> = {
  options: { label: String; id: string }[];
  label: string;
  touched: FormikTouched<ProdutoFormValues>;
  errors: FormikErrors<ProdutoFormValues>;
  changeCallback: (event: any) => void;
  value: T;
};

export default function BaseDropdown<T>({
  label,
  touched,
  errors,
  options,
  changeCallback,
  value,
}: Props<T>) {
  return (
    <>
      <div className={styles.selectGroup}>
        <label htmlFor="unidade">
          {label}
          <span>*</span>
        </label>
        <div className={styles.select}>
          <Dropdown
            id="produto"
            className="customdropdown"
            filter
            options={options}
            onChange={(event) => changeCallback(event)}
            value={value}
          ></Dropdown>
        </div>

        {errors.unidade && touched.unidade ? (
          <Error text={errors.unidade} />
        ) : null}
      </div>
    </>
  );
}
