import React from "react";
import { Input } from "../../../../../components/Input";
import { Error } from "../../../../../components/Error";

import styles from "../../../../../common/Cadastro.module.scss";
import { EditalCadastroFormValues } from "../../../../../assets/interfaces/Edital";
import { FormikErrors, FormikTouched } from "formik";

type Props = {
  handleChange: React.ChangeEventHandler<HTMLInputElement> | undefined;
  values: EditalCadastroFormValues;
  touched: FormikTouched<EditalCadastroFormValues>;
  errors: FormikErrors<EditalCadastroFormValues>;
};

export default function GeneralDataForm({
  handleChange,
  values,
  touched,
  errors,
}: Props) {
  return (
    <>
      <h3>Dados gerais</h3>

      <div className={styles.formGroup}>
        <Input
          isRequired
          label="Nome do edital"
          name="nome"
          onChange={handleChange}
          type="text"
          value={values.nome}
        >
          {errors.nome && touched.nome ? <Error text={errors.nome} /> : null}
        </Input>

        <Input
          isRequired
          label="Número da chamada pública"
          name="chamada_publica"
          onChange={handleChange}
          type="text"
          value={values.chamada_publica}
        >
          {errors.chamada_publica && touched.chamada_publica ? (
            <Error text={errors.chamada_publica} />
          ) : null}
        </Input>
{
/*
        <Input
          isRequired
          label="Data inicial"
          name="data_inicio"
          mask="99/99/9999"
          onChange={handleChange}
          type="text"
          value={values.data_inicio}
        >
          {errors.data_inicio && touched.data_inicio ? (
            <Error text={errors.data_inicio} />
          ) : null}
        </Input>
*/
}


        <Input
          isRequired
          label="Data de inicio da inscrição"
          name="data_inicio_inscricao"
          mask="99/99/9999"
          onChange={handleChange}
          type="text"
          value={values.data_inicio_inscricao}
        >
          {errors.data_inicio_inscricao && touched.data_inicio_inscricao ? (
            <Error text={errors.data_inicio_inscricao} />
          ) : null}
        </Input>

        <Input
          isRequired
          label="Data final para incrição"
          name="data_fim_inscricao"
          mask="99/99/9999"
          onChange={handleChange}
          type="text"
          value={values.data_fim_inscricao}
        >
          {errors.data_fim_inscricao && touched.data_fim_inscricao ? (
            <Error text={errors.data_fim_inscricao} />
          ) : null}
        </Input>

        <Input
          isRequired
          label="Data de seleção dos projetos de venda"
          name="data_selecao"
          mask="99/99/9999"
          onChange={handleChange}
          type="text"
          value={values.data_selecao}
        >
          {errors.data_selecao && touched.data_selecao ? (
            <Error text={errors.data_selecao} />
          ) : null}
        </Input>
{
/*
        <Input
          isRequired
          label="Data final"
          name="data_final"
          mask="99/99/9999"
          onChange={handleChange}
          type="text"
          value={values.data_final}
        >
          {errors.data_final && touched.data_final ? (
            <Error text={errors.data_final} />
          ) : null}
        </Input>
*/
}

        <Input
          isRequired
          label="Número do processo"
          name="numero_processo"
          onChange={handleChange}
          type="text"
          value={values.numero_processo}
        >
          {errors.numero_processo && touched.numero_processo ? (
            <Error text={errors.numero_processo} />
          ) : null}
        </Input>

        <Input
          label="URL do edital"
          name="url_arquivo"
          onChange={handleChange}
          type="text"
          value={values.url_arquivo}
        >
          {errors.url_arquivo && touched.url_arquivo ? (
            <Error text={errors.url_arquivo} />
          ) : null}
        </Input>
      </div>
    </>
  );
}
