import { EscolaCadastroFormValues } from "../../../assets/interfaces/Escola";

export const initialValues: EscolaCadastroFormValues = {
  tipo: 1,
  nome_fantasia: "",
  razao_social: "",
  email_representante: "",
  nome_representante: "",
  cpf_representante: "",
  telefone: "",
  cnpj: "",
  cep: "",
  logradouro: "",
  complemento: "",
  bairro: "",
  cidade: "",
  estado: "",
  senha: "",
  senha_confirmacao: "",
};

// //Use for manual test:
// export const initialValues: EscolaCadastroFormValues = {
//   tipo: 1,
//   nome_fantasia: "JOSÉ CIPRIANO",
//   razao_social: "COLÉGIO ESTADUAL JOSÉ CIPRIANO",
//   email_representante: "52057690@seduc.go.gov.br",
//   nome_representante: "ANA MARIA DA SILVA BATISTA E PAULA",
//   cpf_representante: "454.318.331-20",
//   telefone: "(35) 9 9999-9999",
//   cnpj: "00.683.673/0001-93",
//   cep: "75355-000",
//   logradouro: "RUA JOAQUIM PIRES DE OLIVEIRA, 34",
//   complemento: "",
//   bairro: "Setor Central",
//   cidade: "Varjão",
//   estado: "GO",
//   senha: "CIPRIANO",
//   senha_confirmacao: "CIPRIANO",
// };
