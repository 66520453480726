import React from "react";
import styles from "../../../../../common/Cadastro.module.scss";

type Alimento = {
  id?: string;
  id_edital?: string;
  nome: string;
  unidade: string;
  quantidade: number;
  valor: number;
};

type Props = {
  isEditingAlimento: boolean;
  updateCallBack: () => void;
  addCallback: () => void;
};

export default function AddAlimentoButton({
  isEditingAlimento,
  updateCallBack,
  addCallback,
}: Props) {
  return (
    <>
      <div className={`${styles.add}`}>
        <span></span>
        {isEditingAlimento ? (
          <button type="button" onClick={updateCallBack}>
            Salvar alimento
          </button>
        ) : (
          <button type="button" onClick={addCallback}>
            Adicionar alimento à lista
          </button>
        )}
      </div>
    </>
  );
}
