import { useContext } from 'react';
import { Router, Switch, Route, Redirect } from 'react-router-dom';

import { AuthContext } from '../contexts/AuthContext';

import history from './history';

import Inicio from '../pages/inicio/index';

import AgricultorCadastro from '../pages/agricultor/cadastrar';
import AgricultorAcesso from '../pages/agricultor/acessar';
import AgricultorOpcoes from '../pages/agricultor/index';

import EscolaCadastro from '../pages/escola/cadastrar';
import EscolaAcesso from '../pages/escola/acessar';
import EscolaOpcoes from '../pages/escola/index';

import Producoes from '../pages/agricultor/producoes';
import CadastroProducao from '../pages/agricultor/producao';

import Insumos from '../pages/agricultor/insumos';
import CadastroInsumo from '../pages/agricultor/insumo';

import Editais from '../pages/escola/editais';
import EditalCadastro from '../pages/escola/edital';
import SelecionarProposta from '../pages/escola/propostas';
import PropostasCadastradas from '../pages/escola/contrato';


import EditaisAbertos from '../pages/agricultor/editais/abertos';
import EditaisAplicados from '../pages/agricultor/editais/aplicados';
import EditaisAprovados from '../pages/agricultor/editais/aprovados';
import EditaisFechados from '../pages/agricultor/editais/contrato';

export default function Routes() {
  const { estaAutenticado, usuario } = useContext(AuthContext);

  return (
    <Router history={history}>
      <Switch>
        <Route exact path='/'>
          {estaAutenticado ? (
            usuario?.tipo === 1 ? (
              <Redirect to='/agricultor' />
            ) : (
              <Redirect to='/escola' />
            )
          ) : (
            <Inicio />
          )}
        </Route>

        <Route exact path='/agricultor/cadastrar'>
            <AgricultorCadastro />
        </Route>

        <Route exact path='/agricultor/acessar'>
          {estaAutenticado && usuario?.tipo === 1 ? (
            <Redirect to='/agricultor' />
          ) : estaAutenticado && usuario?.tipo === 2 ? (
            <Redirect to='/escola' />
          ) : (
            <AgricultorAcesso />
          )}
        </Route>

        <Route exact path='/escola/cadastrar'>
          {estaAutenticado && usuario?.tipo === 2 ? (
            <Redirect to='/escola' />
          ) : (
            <EscolaCadastro />
          )}
        </Route>

        <Route exact path='/escola/acessar'>
          {estaAutenticado && usuario?.tipo === 2 ? (
            <Redirect to='/escola' />
          ) : estaAutenticado && usuario?.tipo === 1 ? (
            <Redirect to='/agricultor' />
          ) : (
            <EscolaAcesso />
          )}
        </Route>

        <Route exact path='/escola/editais'>
          <Editais />
        </Route>

        <Route exact path='/escola/edital/:slug?'>
          <EditalCadastro />
        </Route>

        <Route exact path='/escola/propostas'>
          <SelecionarProposta/>
        </Route>

        <Route exact path='/escola/contrato'>
          <PropostasCadastradas/>
        </Route>

        <Route exact path='/escola'>
          {estaAutenticado && usuario?.tipo === 2 ? (
            <EscolaOpcoes />
          ) : (
            <Redirect to='/escola/acessar' />
          )}
        </Route>

        <Route exact path='/agricultor'>
          {estaAutenticado && usuario?.tipo === 1 ? (
            <AgricultorOpcoes />
          ) : (
            <Redirect to='/agricultor/acessar' />
          )}
        </Route>

        <Route exact path='/agricultor/cadastrar/:slug?'>
          <AgricultorCadastro />
        </Route>

        <Route exact path='/agricultor/insumos'>
          <Insumos></Insumos>
        </Route>

        <Route exact path='/agricultor/insumo/:slug?'>
          <CadastroInsumo></CadastroInsumo>
        </Route>

        <Route exact path='/agricultor/producoes'>
          <Producoes></Producoes>
        </Route>

        <Route exact path='/agricultor/producao/:slug?'>
          <CadastroProducao></CadastroProducao>
        </Route>

        <Route exact path='/agricultor/editais/abertos'>
          <EditaisAbertos />
        </Route>

        <Route exact path='/agricultor/editais/aplicados'>
          <EditaisAplicados />
        </Route>

        <Route exact path='/agricultor/editais/aprovados'>
          <EditaisAprovados />
        </Route>

        <Route exact path='/agricultor/editais/aprovados'>
          <EditaisAprovados />
        </Route>

        <Route exact path='/agricultor/editais/contratos'>
          <EditaisFechados />
        </Route>

        <Route
          path='/ufg'
          component={() => {
            window.location.href = 'https://engprod.fct.ufg.br/';
            return null;
          }}
        />

        <Route
          path='/strath'
          component={() => {
            window.location.href = 'https://www.strath.ac.uk/';
            return null;
          }}
        />

        <Route
          path='/usp'
          component={() => {
            window.location.href = 'https://www5.usp.br/';
            return null;
          }}
        />

        <Route
          path='/kit'
          component={() => {
            window.location.href = 'https://www.kit.edu/english/index.php';
            return null;
          }}
        />

        <Route
          path='/upc'
          component={() => {
            window.location.href = 'https://www.upc.edu.pe/';
            return null;
          }}
        />

        <Route
          path='/fapeg'
          component={() => {
            window.location.href = 'http://www.fapeg.go.gov.br/';
            return null;
          }}
        />

        <Route
          path='/newtonFund'
          component={() => {
            window.location.href = 'https://www.newton-gcrf.org/';
            return null;
          }}
        />

        <Route
          path='/britishCouncil'
          component={() => {
            window.location.href = 'https://www.britishcouncil.org/';
            return null;
          }}
        />

        <Route
          path='/cnpq'
          component={() => {
            window.location.href = 'https://www.gov.br/cnpq/pt-br';
            return null;
          }}
        />

        <Route
          path='/mda'
          component={() => {
            window.location.href = 'https://www.gov.br/mda/pt-br';
            return null;
          }}
        />
      </Switch>
    </Router>
  );
}
