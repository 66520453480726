import { Header } from '../../../components/Header';

import history from '../../../routes/history';

import { ArrowRight } from 'react-feather';

import styles from '../../../common/Opcoes.module.scss';

import insumo from '../../../assets/images/insumo.svg';
import producao from '../../../assets/images/producao.svg';
import contratos from '../../../assets/images/deal.svg';
import aprovados from '../../../assets/images/approved.svg';
import candidatura from '../../../assets/images/apply.svg';
import participacao from '../../../assets/images/archive.svg';

export default function AgricultorOpcoes() {
  return (
    <>
      <Header
        titulo='Navegue por categoria'
        descricao='Encontre o serviço ou a informação desejada pelas opções a seguir.'
      />

      <main className={styles.opcoes}>
        {/*<div className={styles.card}>
          <div>
            <h3>Meus insumos</h3>

            <button
              type='button'
              onClick={() => {
                history.push('/agricultor/insumos');
              }}
            >
              Ver e cadastrar insumos
              <ArrowRight color='#333333' size='1rem' />
            </button>
            </div>

          <img src={insumo} alt='Insumos' />
        </div>*/}

        <div className={styles.card}>
          <div>
            <h3>Minhas produções</h3>

            <button
              type='button'
              onClick={() => {
                history.push('/agricultor/producoes');
              }}
            >
              Ver e cadastrar produções
              <ArrowRight color='#333333' size='1rem' />
            </button>
          </div>

          <img src={producao} alt='Produções' />
        </div>

        <div className={styles.card}>
          <div>
            <h3>Candidatar em um edital</h3>

            <button
              type='button'
              onClick={() => {
                history.push('/agricultor/editais/abertos');
              }}
            >
              Editais disponíveis para candidatura
              <ArrowRight color='#333333' size='1rem' />
            </button>
          </div>

          <img src={candidatura} alt='Editais disponíveis para candidatura' />
        </div>

        <div className={styles.card}>
          <div>
            <h3>Editais com participação</h3>

            <button
              type='button'
              onClick={() => {
                history.push('/agricultor/editais/aplicados');
              }}
            >
              Editais que se candidatou
              <ArrowRight color='#333333' size='1rem' />
            </button>
          </div>

          <img src={participacao} alt='Editais com participação' />
        </div>

        <div className={styles.card}>
          <div>
            <h3>Editais aprovados</h3>

            <button
              type='button'
              onClick={() => {
                history.push('/agricultor/editais/aprovados');
              }}
            >
              Editais com a candidatura aprovada
              <ArrowRight color='#333333' size='1rem' />
            </button>
          </div>

          <img src={aprovados} alt='Editais aprovados' />
        </div>

        <div className={styles.card}>
          <div>
            <h3>Contratos fechados</h3>

            <button
              type='button'
              onClick={() => {
                history.push('/agricultor/editais/contratos');
              }}
            >
              Editais com contratos fechados
              <ArrowRight color='#333333' size='1rem' />
            </button>
          </div>

          <img src={contratos} alt='Contratos fechados' />
        </div>
      </main>
      <br />
    </>
  );
}
