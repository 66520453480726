import React from "react";
import styles from "../../../../../common/Cadastro.module.scss";

import { Edit, Trash2 } from "react-feather";
import { Alimento } from "../../../../../assets/interfaces/Edital";

type Props = {
  alimentos: Alimento[];
  handleRemoveAlimento: (id: string) => void;
  handleSetAlimentoToEdit: (alimento: Alimento) => void;
};

export default function RenderAlimentosTable({
  alimentos,
  handleRemoveAlimento,
  handleSetAlimentoToEdit,
}: Props) {
  return (
    <>
      {alimentos.length > 0 && (
        <section className={styles.table}>
          <table>
            <thead>
              <tr>
                <th scope="col">Produto</th>
                <th scope="col">Quantidade</th>
                <th scope="col">Unidade</th>
                <th scope="col">Valor unitário</th>
                <th scope="col">Valor total</th>
                <th scope="col"></th>
              </tr>
            </thead>

            <tbody>
              {alimentos.map((alimento: Alimento) => {
                return (
                  <tr key={alimento.nome}>
                    <td data-label="Produto">{alimento.nome}</td>

                    <td data-label="Quantidade">{alimento.quantidade}</td>

                    <td data-label="Unidade">{alimento.unidade}</td>

                    <td data-label="Valor unitário">
                      {new Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }).format(alimento.valor)}
                    </td>

                    <td data-label="Valor total">
                      {new Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }).format(alimento.valor * alimento.quantidade)}
                    </td>

                    <td data-label="" className={styles.buttons}>
                      <button
                        type="button"
                        className={`${styles.edit} ${styles.icon}`}
                        onClick={() => handleSetAlimentoToEdit(alimento)}
                      >
                        <Edit color="#333333" size="1rem" />
                      </button>

                      <button
                        type="button"
                        className={`${styles.remove} ${styles.icon}`}
                        onClick={() => handleRemoveAlimento(alimento.id!)}
                      >
                        <Trash2 color="#EE5253" size="1rem" />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </section>
      )}
    </>
  );
}
