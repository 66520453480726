import { FocusEvent, useContext, useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import bancos from 'bancos-brasileiros';


import { Error } from '../Error';
import { Input } from '../Input';

import styles from '../../common/Cadastro.module.scss';

interface Banco {
  ISPB: string;
  ShortName: string;
}

interface DadosBancariosValues {
  banco: string;
  agencia: string;
  conta_bancaria: string;
  operacao: string;
}

export default function DadosBancariosForm({isEdditing} : {isEdditing: boolean}) {
  const { values, errors, touched, handleChange } = useFormikContext<DadosBancariosValues>();

  return (
    <>
      <h3>Dados bancários</h3>

      <div className={styles.formGroup}>
        <div className={styles.selectGroup}>
          <label htmlFor='banco'>
            Banco<span>*</span>
          </label>

          <div className={styles.select}>
            <select
              name='banco'
              onChange={handleChange}
              placeholder='Banco'
              value={values.banco}
            >
              <option value='Banco' key='N/A'>
                Banco
              </option>
              {bancos
                .sort(function (a, b) {
                  if (a.ShortName < b.ShortName) {
                    return -1;
                  }
                  if (a.ShortName > b.ShortName) {
                    return 1;
                  }
                  return 0;
                })
                .map((banco: Banco) => {
                  return (
                    <option
                      value={banco.ShortName}
                      key={banco.ISPB}
                    >
                      {banco.ShortName}
                    </option>
                  );
                })}
            </select>
            <div className={styles.selectArrow}></div>
          </div>

          {errors.banco && touched.banco ? (
            <Error text={errors.banco || "teste"} />
          ) : null}
        </div>

        <Input
          isRequired
          label='Agência'
          name='agencia'
          onChange={handleChange}
          type='text'
          placeholder={values.agencia}
        >
          {errors.agencia && touched.agencia ? (
            <Error text={errors.agencia} />
          ) : null}
        </Input>

        <Input
          isRequired
          label='Número da conta'
          name='conta_bancaria'
          onChange={handleChange}
          type='text'
          placeholder={values.conta_bancaria}
        >
          {errors.conta_bancaria && touched.conta_bancaria ? (
            <Error text={errors.conta_bancaria} />
          ) : null}
        </Input>

        <Input
          label='Operação'
          name='operacao'
          onChange={handleChange}
          type='text'
          placeholder={values.operacao}
          helperText='Número de 3 dígitos que indica o tipo de conta.'
        >
          {errors.operacao && touched.operacao ? (
            <Error text={errors.operacao} />
          ) : null}
        </Input>
      </div>
                   

    </>
  );
}
