import Swal from "sweetalert2";
import "animate.css";

export const modalSenha = async () => {
  Swal.fire({
    title: "Esqueceu a senha?",
    text: "Digite seu email cadastrado",
    confirmButtonText: "Enviar instruções ao meu email",
    showCancelButton: false,
    showCloseButton: false,
    showDenyButton: false,
    allowOutsideClick: true,
    allowEnterKey: false,
    allowEscapeKey: true,
    backdrop: true,
    input: "email",
    inputPlaceholder: "Digite seu email",
    showClass: {
      popup: "animate__animated animate__slideInDown animate__faster",
    },
    hideClass: {
      popup: "animate__animated animate__fadeOutUp animate__faster",
    },
  }).then((result) => {
    if (result.isConfirmed) {
    }
  });
};
