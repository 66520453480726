import { useContext, useEffect, useState } from "react";
import _ from 'lodash';

import { Formik, Form, FormikHelpers } from "formik";

import { Header } from "../../../components/Header";

import { useParams } from "react-router-dom";

import {
  Edital,
  EditalAndProdutoFormsValues,
  EditalCadastroFormValues,
  Alimento,
} from "../../../assets/interfaces/Edital";

import {
  EscolaContext,
  NewEditalData,
  RequestedFoodData,
  editarEdital
} from "../../../contexts/EscolaContext";

import { initialValues } from "./initialValues";
import { validationSchema } from "./validationSchema";

import moment from "moment";

import styles from "../../../common/Cadastro.module.scss";
import GeneralDataForm from "./components/GeneralDataForm";
import AlimentosForm from "./components/AlimentosForm";
import RenderAlimentosTable from "./components/RenderAlimentosTable";
import { formatDateMMDDYYYY } from "../../../assets/utils/dateformat";
import { AuthContext } from "../../../contexts/AuthContext";
import { opcoesProdutos } from "../../../assets/utils/constants";


export default function EditalCadastro() {
  const {
    cadastrarEdital,
    buscarEditalPorId,
    alterarEdital,
    getProductOptions,
  } = useContext(EscolaContext);
  const { usuario } = useContext(AuthContext);

  const [edital, setEdital] = useState<Edital | undefined>();
  const [alimentos, setAlimentos] = useState<Alimento[]>([]);
  const [isEditingAlimento, setIsEditingAlimento] = useState(false);
  const [isEditingEdital, setIsEditingEdital] = useState(false);

  const [opcoesProdutos, setOpcoesProdutos] = useState<
    {
      label: string;
      id: string;
    }[]
  >([]);

  let { slug }: any = useParams();

  useEffect(() => {
    async function getOpcoesProdutos() {
      setOpcoesProdutos(await getProductOptions());
    }

    getOpcoesProdutos();
  }, [getProductOptions]);

  useEffect(() => {
    const getEdital = async () => {
      setEdital(await buscarEditalPorId(slug));
    };

    if (slug) {
      getEdital();
      setIsEditingEdital(true);
    }

  }, [slug]);

  function handleRemoveAlimento(id: string) {
    const filteredAlimentos = alimentos.filter(
      (alimento) => alimento.id !== id
    );

    setAlimentos(filteredAlimentos);
  }

  function handleSetAlimentoToEdit(
    alimento: Alimento,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) {
    setFieldValue("alimento_requisitado_id", alimento.id)
    setFieldValue("produto", { label: alimento.nome, id: alimento.alimentosId });
    setFieldValue("unidade", alimento.unidade);
    setFieldValue("quantidade", alimento.quantidade);
    setFieldValue("valor_unitario", alimento.valor);
    setFieldValue("valor_total", alimento.valor * alimento.quantidade);

    setIsEditingAlimento(true);
  }

  function isNumber(value: any): boolean {
    return !isNaN(parseFloat(value)) && isFinite(value as number);
  }

  function normalizeAlimentos(alimento: Alimento): RequestedFoodData {
    let id = '';
    if (!alimento.id || (alimento.id && !isNumber(alimento.id))) {
      id  = alimento.id!;
    }
    return {
      id,
      alimentosId: alimento.alimentosId,
      editaisId: alimento.editaisId,
      quantidade: alimento.quantidade,
      valor: alimento.valor,
    };
  }

  function normalizeEdital(values: EditalCadastroFormValues) {
    const edital: NewEditalData = {
      nome: values.nome,
      data_inicio: moment(values.data_inicio_inscricao, "DD/MM/YYYY").format('YYYY-MM-DD'),                //! remover data_inicio nas proximas releases
      data_final: moment(values.data_fim_inscricao, "DD/MM/YYYY").format('YYYY-MM-DD'),                    //! remover data_final nas proximas releases
      data_inicio_inscricao: moment(values.data_inicio_inscricao, "DD/MM/YYYY").format('YYYY-MM-DD'),
      data_fim_inscricao: moment(values.data_fim_inscricao, "DD/MM/YYYY").format('YYYY-MM-DD'),
      data_selecao: moment(values.data_selecao, "DD/MM/YYYY").format('YYYY-MM-DD'),
      chamada_publica: values.chamada_publica,
      numero_processo: values.numero_processo,
      url_arquivo: values.url_arquivo,
      entidade: { id: usuario!.id },
      alimentos: alimentos.map(normalizeAlimentos),
    };

    return edital;
  }

  
  const handleSubmitForm = async (
    values: EditalAndProdutoFormsValues,
    actions: FormikHelpers<EditalAndProdutoFormsValues>
  ) => {
    actions.validateForm();
    // actions.resetForm();

    const novoEdital = normalizeEdital(values);
    if (!slug) {
      cadastrarEdital(novoEdital);
    } else {
      if(edital) {
        const editalEditado: editarEdital = {
          id: edital.id,
          nome: values.nome,
          data_inicio: moment(values.data_inicio, "DD/MM/YYYY").toDate(),
          data_final: moment(values.data_final, "DD/MM/YYYY").toDate(),
          data_inicio_inscricao: moment(values.data_inicio_inscricao, "DD/MM/YYYY").toDate(),
          data_fim_inscricao: moment(values.data_fim_inscricao, "DD/MM/YYYY").toDate(),
          data_selecao: moment(values.data_selecao, "DD/MM/YYYY").toDate(),
          chamada_publica: values.chamada_publica,
          numero_processo: values.numero_processo,
          url_arquivo: values.url_arquivo,
          alimentos: alimentos.map(normalizeAlimentos)
        }
   
        alterarEdital(editalEditado);
      } else {
        throw "Edital não encontrado"
      }
    }
  }

  return (
    <>
      <Header
        titulo={isEditingEdital ? "Editar edital" : "Cadastrar edital"}
        descricao="Informe os dados gerais e os alimentos do edital no formulário abaixo."
      />

      <main className={styles.cadastro}>
        <div className={styles.formCard}>
          <h2>{isEditingEdital ? "Editar edital" : "Cadastrar edital"}</h2>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmitForm}
          >
            {function ShowForm({
              touched,
              values,
              errors,
              handleChange,
              setFieldValue,
            }) {
              useEffect(() => {
                if (edital) {
                  setFieldValue("id", edital.id);
                  setFieldValue("nome", edital.nome);
                  setFieldValue("chamada_publica", edital.chamada_publica);
                  setFieldValue(
                    "data_inicio",
                    moment(edital.data_inicio).format("DD/MM/YYYY")
                  );
                  setFieldValue(
                    "data_final",
                    moment(edital.data_final).format("DD/MM/YYYY")
                  );
                  setFieldValue(
                    "data_inicio_inscricao",
                    moment(edital.data_inicio_inscricao).format("DD/MM/YYYY")
                  );
                  setFieldValue(
                    "data_fim_inscricao",
                    moment(edital.data_fim_inscricao).format("DD/MM/YYYY")
                  );
                  setFieldValue(
                    "data_selecao",
                    moment(edital.data_selecao).format("DD/MM/YYYY")
                  );
                  setFieldValue("numero_processo", edital.numero_processo);
                  setFieldValue("url_arquivo", edital.url_arquivo);

                  setAlimentos(edital.alimentos);
                }
                // eslint-disable-next-line react-hooks/exhaustive-deps
              }, [edital]);

              return (
                <Form>
                  <section>
                    <GeneralDataForm
                      handleChange={handleChange}
                      values={values}
                      touched={touched}
                      errors={errors}
                    ></GeneralDataForm>
                  </section>

                  <section>
                    <AlimentosForm
                      dropdownOptions={opcoesProdutos}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      values={values}
                      touched={touched}
                      errors={errors}
                      isEditingAlimento={isEditingAlimento}
                      setIsEditingAlimento={setIsEditingAlimento}
                      alimentos={alimentos}
                      setAlimentos={setAlimentos}
                    ></AlimentosForm>
                  </section>

                  <RenderAlimentosTable
                    alimentos={alimentos}
                    handleRemoveAlimento={handleRemoveAlimento}
                    handleSetAlimentoToEdit={(alimento) =>
                      handleSetAlimentoToEdit(alimento, setFieldValue)
                    }
                  ></RenderAlimentosTable>

                  <section>
                    <div className={styles.formGroup}>
                      <span></span>
                      <button type="submit">
                        {isEditingEdital
                          ? "Finalizar edição do edital"
                          : "Finalizar cadastro do edital"}
                      </button>
                    </div>
                  </section>
                </Form>
              );
            }}
          </Formik>
        </div>
      </main>
      <br />
    </>
  );
}
