import "./styles.scss";

interface StatusProps {
  status: number;
}

export function Status({ status }: StatusProps) {
  switch (status) {
    case 1:
      return (
        <span className="status aguardando-inicio">Aguardando início</span>
      );
    case 2:
      return <span className="status suspenso">Fechado</span>;
    case 3:
      return <span className="status em-andamento">Aplicado</span>;
    case 4:
      return <span className="status selecionado">Aprovado</span>;
    case 5:
      return <span className="status encerrado">Reprovado</span>;
    case 6:
      return <span className="status recomendado">Assinado</span>;
    case 7:
      return <span className="status recomendado">Recomendado</span>;
    case 8:
      return <span className="status nao-recomendado">Não recomendado</span>;
    default:
      return <span></span>;
    // case 2:
    //   return <span className="status suspenso">Suspenso</span>;
    // case 3:
    //   return <span className="status em-andamento">Em andamento</span>;
    // case 4:
    //   return <span className="status encerrado">Encerrado</span>;
    // case 5:
    //   return <span className="status selecionado">Selecionado</span>;
    // case 6:
    //   return <span className="status recomendado">Recomendado</span>;
    // default:
    //   return <span></span>;
  }
}
